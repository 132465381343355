<template>

  <v-layout
      align-center
      justify-center

  >

    <v-card class="elevation-12"
            style="
            margin-top: 50px;
            width: 400px"
    >

      <v-card-text>
        <v-card-title></v-card-title>
        <v-form>
          <v-text-field
              v-model="username"
              label="Benutzername"
              type="text"
          ></v-text-field>
          <v-text-field
              v-model="password"
              label="Passwort"
              type="password"
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="login">Login</v-btn>
      </v-card-actions>
    </v-card>


    <v-snackbar
        v-model="snackbar"
        :timeout="timeout"
        :color="snackbar_color"

    >
      {{ snackbar_title }}
      <!--
            <template v-slot:action="{ attrs }">
              <v-btn
                  color="white"
                  text
                  v-bind="attrs"
                  @click="snackbar = false"
              >
                Close
              </v-btn>
            </template>
       -->

    </v-snackbar>




  </v-layout>

</template>

<script>
export default {
  name: "Login",

  data () {
    return {

      snackbar: false,
      snackbar_title: 'Gespeichert!',
      snackbar_color: 'red',
      timeout: 1000,

      username: '',
      password: '',
      typ: '',


    }
  },



  created() {

    localStorage.username = ""
    localStorage.typ = ""
    localStorage.customer_id = ""
    localStorage.customer_name = ""
    localStorage.product = ""

  },


  methods: {

    async login(){

      console.log("Login")

      if(this.username == ""){
        this.snackbar_title = "Benutzername eingeben!"
        this.snackbar_color = "red"
        this.snackbar = true
        return
      }
      if(this.password == ""){
        this.snackbar_title = "Passwort eingeben!"
        this.snackbar_color = "red"
        this.snackbar = true
        return
      }



      if(this.username == "admin"){
        if(this.password == "1234"){

          localStorage.username = "admin"
          localStorage.typ = "admin"
          localStorage.customer_id = ""
          localStorage.customer_name = "Admin"

          this.$router.push({ name: 'Dashboard' })

          return
        }
      }


      // Einstellungen
      var param = {
        table: "customer",
        statement: "select",
        type: "detail",
        where: "true",
        selector: "username",
        value: this.username,
        short: "true", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {
      }

      var result = await this.$func.queryAPI(param, data)

      console.log(result)


      if(result.length == 0){
        this.snackbar_title = "Benutzer nicht vorhanden!"
        this.snackbar_color = "red"
        this.snackbar = true
        return
      }

      if (result[0]["password"] != this.password){
        this.snackbar_title = "Falsches Passwort!"
        this.snackbar_color = "red"
        this.snackbar = true
        return
      }


      localStorage.username = this.username
      localStorage.typ = "user"
      localStorage.customer_id = result[0]["id"]
      localStorage.customer_name = result[0]["name"]

      this.$router.push({ name: 'Dashboard' })



    }



  }


}
</script>

<style scoped>

</style>
