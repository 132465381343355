import Vue from 'vue'
import VueRouter from 'vue-router'

import Dashboard from '../views/Dashboard'
import ReleaseLists from '../views/ReleaseLists'
import DetailReleaseList from '../views/DetailReleaseList'
import Projects from '../views/Projects'
import Login from '../views/Login'

import FormCustomer from '../views/FormCustomer'
import FormProject from '../views/FormProject'
import FormRelease from '../views/FormRelease'

import FormCity from '../views/FormCity'
import FormMedium from '../views/FormMedium'

import ListProject from '../views/ListProject'
import ListCity from '../views/ListCity'
import ListMedium from '../views/ListMedium'
import ListCustomer from '../views/ListCustomer'
import ListRelease from '../views/ListRelease'
import Upload from '../views/Upload'

import Ads from '../views/Ads'
import TopProjects from '../views/TopProjects'
import Infoclip from '../views/Infoclip'
import Recherche from '../views/Recherche'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard
  },
  {
    path: '/Dashboard',
    name: 'Dashboard',
    component: Dashboard
  },
  {
    path: '/ReleaseLists',
    name: 'ReleaseLists',
    component: ReleaseLists
  },
  {
    path: '/DetailReleaseList/:id',
    name: 'DetailReleaseList',
    component: DetailReleaseList
  },
  {
    path: '/Projects',
    name: 'Projects',
    component: Projects
  },
  {
    path: '/ListCity',
    name: 'ListCity',
    component: ListCity
  },
  {
    path: '/ListMedium',
    name: 'ListMedium',
    component: ListMedium
  },
  {
    path: '/ListProject',
    name: 'ListProject',
    component: ListProject
  },
  {
    path: '/ListRelease',
    name: 'ListRelease',
    component: ListRelease
  },
  {
    path: '/ListCustomer',
    name: 'ListCustomer',
    component: ListCustomer
  },
  {
    path: '/Upload',
    name: 'Upload',
    component: Upload
  },
  {
    path: '/FormCustomer/:id',
    name: 'FormCustomer',
    component: FormCustomer
  },
  {
    path: '/FormProject/:id',
    name: 'FormProject',
    component: FormProject
  },
  {
    path: '/FormProject/',
    name: 'FormProject',
    component: FormProject
  },
  {
    path: '/FormRelease/:id',
    name: 'FormRelease',
    component: FormRelease
  },


  {
    path: '/FormRelease/',
    name: 'FormRelease',
    component: FormRelease
  },

  {
    path: '/FormCity/:id',
    name: 'FormCity',
    component: FormCity
  },
  {
    path: '/FormMedium/:id',
    name: 'FormMedium',
    component: FormMedium
  },
  {
    path: '/FormMedium/',
    name: 'FormMedium',
    component: FormMedium
  },
  {
    path: '/Ads',
    name: 'Ads',
    component: Ads
  },
  {
    path: '/Recherche',
    name: 'Recherche',
    component: Recherche
  },
  {
    path: '/TopProjects',
    name: 'TopProjects',
    component: TopProjects
  },
  {
    path: '/Infoclip',
    name: 'Infoclip',
    component: Infoclip
  },

  {
    path: '/Login',
    name: 'Login',
    component: Login
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
