<template>
  <v-layout
  >

    <v-card

        v-if="renderComponent"

        class="mx-auto justify-center text-center"
        width="650"
        style="padding-top: 30px;"

        elevation-10
    >
      <v-card-title primary-title class="justify-center">
        <div>
          <h3 class="headline text--accent-2">{{ this.title }}</h3>
        </div>
      </v-card-title>


      <v-form
          autocomplete="off"
      >


        <v-text-field
            v-model="edatum"
            sm="12"
            md="4"
            label="Datum"
            required
            outlined
            placeholder="DD.MM.YYYY"
            type="search"
            autocomplete="off"
            style="
        display: table;
        margin: 0 auto;
        width: 350px;"

        ></v-text-field>



        <CustomAutocomplete
            v-model="medium"
            v-bind:table="'medium'"
            v-bind:field_text="'name'"
            v-bind:field_value="'name'"
            v-bind:label="'Medium'"
            v-bind:selected="medium"
            v-bind:extension="'ORDER BY name ASC'"

            style="
        display: table;
        margin: 0 auto;
        width: 350px;"
        >

        </CustomAutocomplete>

        <CustomAutocomplete
            v-model="ort"
            v-bind:table="'city'"
            v-bind:field_text="'name'"
            v-bind:field_value="'name'"
            v-bind:label="'Ort'"
            v-bind:selected="ort"
            v-bind:extension="'ORDER BY name ASC'"

            style="
        display: table;
        margin: 0 auto;
        width: 350px;"
        >

        </CustomAutocomplete>

        <v-combobox
            sm="12"
            md="4"
            v-model="gattung"
            :items="items_gattung"
            label="Gattung"
            outlined
            style="
        display: table;
        margin: 0 auto;
        width: 350px;"
        ></v-combobox>


        <v-switch
        v-model="newsletter"
        label="Newsletter"

        style="
      display: table;
      margin: 0 auto;
      width: 350px;"
        >

        </v-switch>



        <CustomSelect

            v-if="user_type == 'admin'"

            v-model="kunde"
            v-bind:table="'customer'"
            v-bind:field_text="'name'"
            v-bind:field_value="'name'"
            v-bind:label="'Kunde'"
            v-bind:selected="kunde"
            v-bind:extension="extension_custom_select_customer"

            style="
            display: table;
            margin: 0 auto;
            width: 350px;"
        >

        </CustomSelect>



        <CustomSelect
            v-model="projekt"
            v-bind:table="'project'"
            v-bind:field_text="'name'"
            v-bind:field_value="'name'"
            v-bind:label="'Projekt'"
            v-bind:selected="projekt"
            v-bind:extension="extension_custom_select_project"

            style="
        display: table;
        margin: 0 auto;
        width: 350px;"
        >

        </CustomSelect>


<!--
        <v-text-field
            v-model="projekt"
            sm="12"
            md="4"
            label="Projekt"
            required
            outlined
            type="search"
            autocomplete="off"
            style="
      display: table;
      margin: 0 auto;
      width: 350px;"

        ></v-text-field>
-->

        <v-select
            v-if="user_type == 'admin' || kunde.toLowerCase() == 'oknoplast'"
            sm="12"
            md="4"
            v-model="untertitel"
            :items="items_untertitel"
            label="Beleg Typ (Beleg, Infobeleg, Werbung)"
            outlined
            style="
        display: table;
        margin: 0 auto;
        width: 350px;"
        ></v-select>

        <v-text-field
            v-model="auflage"
            sm="12"
            md="4"
            label="Auflage"
            required
            outlined
            type="search"
            autocomplete="false"
            style="
      display: table;
      margin: 0 auto;
      width: 350px;"

        ></v-text-field>

        <v-text-field
            v-model="reichweite"
            sm="12"
            md="4"
            label="Reichweite / (Visits)"
            required
            outlined
            type="search"
            autocomplete="false"
            style="
      display: table;
      margin: 0 auto;
      width: 350px;"

        ></v-text-field>

        <v-text-field
            v-model="umfang"
            sm="12"
            md="4"
            label="Seitenumfang"
            required
            outlined
            type="search"
            autocomplete="false"
            style="
      display: table;
      margin: 0 auto;
      width: 350px;"

        ></v-text-field>


        <v-text-field
            v-model="aäwg"
            sm="12"
            md="4"
            label="Äquivalenzwert"
            required
            outlined
            type="search"
            autocomplete="false"
            style="
      display: table;
      margin: 0 auto;
      width: 350px;"

        ></v-text-field>


        <v-select
            v-model="observer"
            style="
            display: table;
            margin: 0 auto;
            width: 350px;"
            outlined
            :items="items_observer"
            label="Beobachter"
        >
        </v-select>


        <v-text-field
            v-model="observer_id"
            sm="12"
            md="4"
            label="Beobachter Ref Nr."
            outlined
            type="search"
            autocomplete="false"
            style="
      display: table;
      margin: 0 auto;
      width: 350px;"

        ></v-text-field>



        <v-text-field

            style="
      display: table;
      margin: 0 auto;
      width: 350px;"

            v-model="url"

            label="URL"
            required
            outlined
            type="search"
            autocomplete="false"


        ></v-text-field>

        <v-btn
            style="margin-bottom: 30px;"
            width="40"
            height="50"
        @click="openURL"
        ><v-icon>mdi-web</v-icon></v-btn>




        <v-textarea
            v-model="bemerkung"
            outlined
            label="Bemerkungen"
            value=""
            style="
      display: table;
      margin: 0 auto;
      width: 350px;"
        ></v-textarea>


        <div
        v-if="id != ''"
        >

        <cImageUpload
        style="margin: 30px;"
            v-if="renderComponent"
            v-bind:title="'Belege'"
            v-bind:value="id"
            v-bind:selector="'releaselists_id'"
            v-bind:table="'images'"
        ></cImageUpload>
        </div>

        <div
            style="margin-bottom: 150px;"
        ></div>

        <v-divider></v-divider>


        <!--
        </v-col>
        -->

        <v-row
        style="margin: 50px;"
        >

          <v-col
              sm="10"
              md="10"
              class="text-right"
          >

            <v-btn
                color="red"
                elevation="2"
                outlined
                style="margin: 40px"
                @click="btnCancel"
            >Abbrechen</v-btn>


            <v-btn
                color="green"
                elevation="2"
                outlined
                @click="saveData"
            >Speichern</v-btn>


          </v-col>

        </v-row>




      </v-form>

      <v-spacer />


    </v-card>


    <v-snackbar
        v-model="snackbar"
        :timeout="timeout"
        :color="snackbar_color"

    >
      {{ snackbar_title }}
      <!--
            <template v-slot:action="{ attrs }">
              <v-btn
                  color="white"
                  text
                  v-bind="attrs"
                  @click="snackbar = false"
              >
                Close
              </v-btn>
            </template>
       -->

    </v-snackbar>


  </v-layout>
</template>

<script>

import CustomAutocomplete from '@/components/CustomAutocomplete'
import CustomSelect from '@/components/CustomSelect'
import cImageUpload from '@/components/cImageUpload'


export default {
  name: "FormRelease",

  components: {
    CustomSelect,
    CustomAutocomplete,
    cImageUpload,

  },

  data () {
    return {

      title: 'Veröffentlichung',
      mainview: 'ListRelease',
      table: 'releaselists',

      snackbar: false,
      snackbar_title: 'Gespeichert!',
      snackbar_color: 'red',
      timeout: 4000,

      renderComponent: true,

      id: '',
      edatum: '',
      medium: '',
      untertitel: '',
      ort: '',
      kunde: '',
      projekt: '',
      umfang: '',
      auflage: '',
      reichweite: '',
      gattung: '',
      newsletter: '',
      url: '',
      aäwg: '',


      extension_custom_select_customer: "SELECT * FROM customer ORDER BY name ASC",
      extension_custom_select_project: "SELECT * FROM project WHERE customer_name='" + this.kunde + "' ORDER BY name ASC",


      user_type: '',

      observer: '',
      observer_id: '',

      bemerkung: '',

      items_observer: ['', 'Argus', 'Landau'],

      items_gattung: [
        '',
        'Anzeigenblätter',
        'Zeitung',
        'Fachzeitschrift',
        'Publikumszeitschrift',
        'Tageszeitungen',
        'Publikumszeitschrift',
        'Online-Medien',
      ],


      items_untertitel: [
        '',
        'Beleg',
        'Infobeleg',
        'Werbung',
        'WnD',
        'Aluhaus',
      ],



    }
  },

  created(){

    this.user_type = localStorage.typ


    if(this.$route.params.id != undefined){
      this.id = this.$route.params.id
      this.loadData()
    }



  },


  mounted() {

    console.log("mounted")
    console.log(localStorage.username)

    if(localStorage.username == "oknoplast"){
    console.log("oknoplast")
    this.kunde = "oknoplast"
    }

  },


  watch:{

    async kunde(){

      console.log("kunde: " + this.kunde)

      if(this.kunde == ""){
        return
      }

      this.extension_custom_select_project = "SELECT * FROM project WHERE customer_name='" + this.kunde + "' ORDER BY name ASC"

      // Remove MyComponent from the DOM
      this.renderComponent = false;


      // Wait for the change to get flushed to the DOM
      await this.$nextTick();

      // Add the component back in
      this.renderComponent = true;

    },


    async medium(){

      this.checkMedium(this.medium)

    }


  },



  methods: {

    async checkMedium(name){

      // Einstellungen
      var param = {
        table: "medium",
        statement: "select",
        type: "detail",
        where: "true",
        selector: "name",
        value: name,
        short: "true", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {
      }

      var result = await this.$func.queryAPI(param, data)
      console.log(result)

      if(result.length == 0){
        return
      }


      this.auflage = result[0]["auflage"]
      this.ort = result[0]["ort"]
      this.gattung = result[0]["gattung"]





    },


    openURL(){

      if(this.url == ""){
        return
      }

      window.open(this.url);

    },


    async getCustomerIDbyName(name){

      // Einstellungen
      var param = {
        table: "customer",
        statement: "select",
        type: "detail",
        where: "true",
        selector: "name",
        value: name,
        short: "true", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {
      }

      var result = await this.$func.queryAPI(param, data)
      console.log(result)

      if(result.length == 0){
        return ""
      }

      return result[0]["id"]


    },



    btnCancel(){

      if(localStorage.username.toString().toLowerCase() != "admin"){
        this.$router.push({ name: 'Recherche' })
        return
      }

      this.$router.push({ name: this.mainview })

    },


    async loadData(){

      // Einstellungen
      var param = {
        table: this.table,
        statement: "select",
        type: "detail",
        where: "true",
        selector: "id",
        value: this.id,
        short: "true", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {
      }

      var result = await this.$func.queryAPI(param, data)
      console.log(result)

      this.edatum = result[0]["edatum"]
      this.medium = result[0]["medium"]
      this.ort = result[0]["ort"]
      this.untertitel = result[0]["untertitel"]
      this.kunde = result[0]["kunde"]
      this.projekt = result[0]["projekt"]
      this.gattung = result[0]["gattung"]
      this.newsletter = result[0]["newsletter"]
      this.umfang = result[0]["umfang"]
      this.auflage = result[0]["auflage"]
      this.reichweite = result[0]["reichweite"]
      this.bemerkung = result[0]["bemerkung"]
      this.observer = result[0]["observer"]
      this.observer_id = result[0]["observer_id"]
      this.aäwg =  result[0]["aäwg"]
      this.url = result[0]["url"]

      console.log("this.projekt")
      console.log(this.projekt)


    },



    async saveData(){

      var customer_id = await this.getCustomerIDbyName(this.kunde)

      var statement

      if(this.edatum == ""){
        this.snackbar_title = "Bitte Datum eingeben!"
        this.snackbar_color = "red"
        this.snackbar = true
        return
      }

      if(this.id == ""){
        statement = "insert"
      }else{
        statement = "update"
      }


      // Einstellungen
      var param = {
        table: this.table,
        statement: statement,
        type: "",
        where: "true",
        selector: "id",
        value: this.id,
        short: "true", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {
        edatum: this.edatum,
        date_timestamp: this.$moment(this.edatum, "DD.MM.YYYY").format("YYYY-MM-DD"),
        customer_id: customer_id,
        ort: this.ort,
        kunde: this.kunde,
        medium: this.medium,
        projekt: this.projekt,
        untertitel: this.untertitel,
        gattung: this.gattung,
        newsletter: this.newsletter,
        aäwg: this.aäwg,
        umfang: Number(this.umfang.replace(",", ".")).toFixed(2),
        auflage: this.auflage.replace(".", "").replace(",",""),
        reichweite: this.reichweite.replace(".", "").replace(",",""),
        bemerkung: this.bemerkung,
        observer_id: this.observer_id,
        observer: this.observer,
        url: this.url,
        angelegt_von: localStorage.username,
        state: '',
        titel: '',
        aäw_ung: '',
        prwfaktor: '',
        prwert: '',
        jornform: '',
        thema: '',
        medienart: '',
        liste: '',
        tenor: '',
        exklusiv: '',
        bezahlt: '',
        kommentar: '',
        redakteur: '',
        ressort: '',
        scans: '',
        register: '',
        beurteilung: '',
        size: '',
        date: '',
        listno: '',
        seitennummer: '',
        erscheinungsweise: '',

      }

      console.log(data)

      var result = await this.$func.queryAPI(param, data)
      console.log(result)


      if(this.auflage != "0" && this.auflage != ""){


        // Einstellungen
        var param1 = {
          table: "medium",
          statement: "update",
          type: "",
          where: "true",
          selector: "name",
          value: this.medium,
          short: "true", // Übermittlung nur von id
          // filter: "false",
          // filterfield: "",
          // filtervalue: "",
          // operator: "true",
          // Filter noch hinzufügen
          extension: "",
        }

        // Daten -> Werden nur bei Insert oder Update gesendet
        var data1 = {
          auflage: this.auflage,
        }

        var result1 = await this.$func.queryAPI(param1, data1)
        console.log("UPDATE Auflage")
        console.log(data1)
        console.log(result1)

      }


      if(this.gattung != ""){


        // Einstellungen
        var param2 = {
          table: "medium",
          statement: "update",
          type: "",
          where: "true",
          selector: "name",
          value: this.medium,
          short: "true", // Übermittlung nur von id
          // filter: "false",
          // filterfield: "",
          // filtervalue: "",
          // operator: "true",
          // Filter noch hinzufügen
          extension: "",
        }

        // Daten -> Werden nur bei Insert oder Update gesendet
        var data2 = {
          gattung: this.gattung,
        }

        var result2 = await this.$func.queryAPI(param2, data2)
        console.log("UPDATE Gattung")
        console.log(data2)
        console.log(result2)

      }


      this.snackbar_title = "Gespeichert!"
      this.snackbar_color = "green"
      this.snackbar = true

      if(statement == "insert"){
        this.id = result["value"].toString()
      }



    },


  }




}
</script>

<style scoped>

</style>
