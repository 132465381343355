<template>
  <v-layout
  >

    <v-card
        class="mx-auto justify-center text-center"
        width="600"
        style="padding-top: 30px;"

        elevation-10
    >
      <v-card-title primary-title class="justify-center">
        <div>
          <h3 class="headline text--accent-2">{{ this.title }}</h3>
        </div>
      </v-card-title>


      <v-form
          autocomplete="off"
      >

        <v-text-field
            v-model="name"
            sm="12"
            md="4"
            label="Name"
            required
            outlined
            type="search"
            autocomplete="off"
            style="
        display: table;
        margin: 0 auto;
        width: 350px;"

        ></v-text-field>

        <v-text-field
            v-model="ort"
            sm="12"
            md="4"
            label="Ort"
            required
            outlined
            type="search"
            autocomplete="off"
            style="
        display: table;
        margin: 0 auto;
        width: 350px;"

        ></v-text-field>

        <v-text-field
            v-model="auflage"
            sm="12"
            md="4"
            label="Auflage / Reichweite (Visits)"
            required
            outlined
            type="search"
            autocomplete="off"
            style="
        display: table;
        margin: 0 auto;
        width: 350px;"

        ></v-text-field>
<!--
        <v-text-field
            v-model="reichweite"
            sm="12"
            md="4"
            label="Reichweite"
            required
            outlined
            type="search"
            autocomplete="off"
            style="
        display: table;
        margin: 0 auto;
        width: 350px;"

        ></v-text-field>
-->
        <v-text-field
            v-model="erscheinungsweise"
            sm="12"
            md="4"
            label="Erscheinungsweise"
            required
            outlined
            type="search"
            autocomplete="off"
            style="
        display: table;
        margin: 0 auto;
        width: 350px;"

        ></v-text-field>

        <v-combobox
        v-model="gattung"
        :items="items"
        label="Gattung"
        outlined
        autocomplete="on"
        style="
        display: table;
        margin: 0 auto;
        width: 350px;"
        ></v-combobox>


        <v-text-field
            v-model="email"
            sm="12"
            md="4"
            label="E-Mail Redaktion"
            required
            outlined
            type="search"
            autocomplete="off"
            style="
        display: table;
        margin: 0 auto;
        width: 350px;"

        ></v-text-field>


        <div
            style="padding: 10px;"
        ></div>

        <v-divider></v-divider>

        <div
            style="padding: 10px;"
        ></div>

        <div
            style="padding: 10px;"
        ></div>




        <!--
        </v-col>
        -->

        <v-row
            style="padding: 10px"
        >

          <v-col
              sm="10"
              md="10"
              class="text-right"
          >

            <v-btn
                color="red"
                elevation="2"
                outlined
                style="margin-right: 40px"
                @click="btnCancel"
            >Abbrechen</v-btn>


            <v-btn
                color="green"
                elevation="2"
                outlined
                @click="saveData"
            >Speichern</v-btn>


          </v-col>

        </v-row>

        <div
            style="padding: 10px;"
        ></div>


      </v-form>

      <v-spacer />


    </v-card>


    <v-snackbar
        v-model="snackbar"
        :timeout="timeout"
        :color="snackbar_color"

    >
      {{ snackbar_title }}
      <!--
            <template v-slot:action="{ attrs }">
              <v-btn
                  color="white"
                  text
                  v-bind="attrs"
                  @click="snackbar = false"
              >
                Close
              </v-btn>
            </template>
       -->

    </v-snackbar>


  </v-layout>
</template>

<script>
export default {
  name: "FormMedium",

  data () {
    return {

      title: 'Medium',
      mainview: 'ListMedium',
      table: 'medium',

      snackbar: false,
      snackbar_title: 'Gespeichert!',
      snackbar_color: 'red',
      timeout: 4000,

      id: '',
      name: '',
      ort: '',
      auflage: '',
      reichweite: '',
      gattung: '',
      erscheinungsweise: '',
      email: '',

      items: ['Fachzeitschrift', 'Zeitung', 'Online-Medien', 'Anzeigenblätter', 'Sonstige'],


    }
  },

  created(){

    if(this.$route.params.id != undefined){
      this.id = this.$route.params.id

      this.loadData()

    }

  },


  methods: {

    btnCancel(){

      this.$router.push({ name: this.mainview })

    },

    async loadData(){

      // Einstellungen
      var param = {
        table: this.table,
        statement: "select",
        type: "detail",
        where: "true",
        selector: "id",
        value: this.id,
        short: "true", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {
      }

      var result = await this.$func.queryAPI(param, data)
      console.log(result)

      this.name = result[0]["name"]
      this.ort = result[0]["ort"]
      this.gattung = result[0]["gattung"]
      this.auflage = result[0]["auflage"]
      this.reichweite = result[0]["reichweite"]
      this.erscheinungsweise = result[0]["erscheinungsweise"]
      this.email = result[0]["email"]

    },

    async saveData(){

      var statement

      if(this.id == ""){
        statement = "insert"
      }else{
        statement = "update"
      }

      // Einstellungen
      var param = {
        table: this.table,
        statement: statement,
        type: "",
        where: "true",
        selector: "id",
        value: this.id,
        short: "true", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {

        name: this.name,
        //  customer_name: result[0]["customer_name"],
        ort: this.ort,
        gattung: this.gattung,
        auflage: this.auflage,
        reichweite: this.reichweite,
        erscheinungsweise: this.erscheinungsweise,
        email: this.email,
        verlag: '',
      }

      var result = await this.$func.queryAPI(param, data)
      console.log(result)

      this.snackbar_title = "Gespeichert!"
      this.snackbar_color = "green"
      this.snackbar = true

      if(statement == "insert"){
        this.id = result["value"].toString()
      }



    },

  }



}
</script>

<style scoped>

</style>
