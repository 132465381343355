<template>
  <div
      style="margin: 30px;"
  >

    <div
        style="
  width: 100%;
  max-width: 1200px;
  display: flex;

  "
    >
      <div
          style="width: 50%;"
      >
        <h2> {{$t("advertisment")}} {{kunde}}</h2>
      </div>
      <div
          style="
    text-align: right;
    width: 50%"
      >
        <!--
      <v-btn
          class="mx-2"
          fab
          dark
          color="#CA0931"
          @click="back2ReleaseList"
      >
        <v-icon
        >
          mdi-close
        </v-icon>

      </v-btn>
      -->

        <v-btn
            outlined
            class="mx-2"
            color="#CA0931"
            @click="back2ReleaseList"
        >
          {{$t("back")}}
        </v-btn>


      </div>
    </div>

    <div
        style="margin: 15px"
    ></div>
    <div
        style="
  max-width: 1200px;
  border-bottom: 1px solid black;
  "
    >

      <table
          style="
        text-align: left;
        font-weight: bold;
        "
      >
        <td
            style="
      width: 650px;
      "
        >
            {{$t("titel")}}
        </td>

        <td
            style="
      width: 150px;
      "
        >
            {{$t("termin")}}
        </td>

        <td
            style="
      width: 50px;
      "
        >
            {{$t("beleg")}}
        </td>


      </table>

    </div>

    <div
        style="margin: 15px"
    ></div>

    <div
        v-for="gattung in gattungen"
        :key="gattung.id"

    >
      <div
          style="
    margin-top: 15px;
    margin-bottom: 15px;
    "
      >
        <h3>{{gattung.name}}</h3>
      </div>

      <table
          v-for="beleg in belege.filter(obj => obj.gattung == gattung.name)"
          :key="beleg.id"

          style="

        text-align: left;
        "
      >
        <td
            style="
      width: 650px;
      "
        >
          {{beleg.medium}}
        </td>


        <!-- 150px -->
        <td
            style="
      width: 150px;
      "
        >
          {{beleg.edatum}}
        </td>

        <td
            style="
      width: 50px;
      "
        >
          <div
              v-if="beleg.images == 'true'"
          >
            <v-icon
                @click="showBeleg(beleg.id)"
            >
              mdi-information-outline
            </v-icon>
          </div>
        </td>


      </table>




    </div>
    <div
        style="margin: 20px"
    ></div>






    <div
        style="margin: 50px"
    ></div>


    <v-overlay
        v-if="overlay"
        @click="overlay = false"
        style="
  background-color: green;
        height: 100%;

        "
    >
      <div
          style="
         height: 100%;
         width: 100%;
        "
      >
        <img


            :src="image_url"
            style="
        max-height: 70%;
        width: auto;
        "
        >
      </div>
    </v-overlay>


  </div>
</template>

<script>

import Vue from "vue";

export default {
  name: "Infoclip",

  data: () => ({
    title: '',
    kunde: '',
    id: '',
    gattungen: [],
    projekte: [],
    belege: [],

    year_start: '',
    year_end: '',

    customer_name: localStorage.customer_name,



    kumuliert_print_belege: 0,
    kumuliert_print_auflage: 0,
    kumuliert_print_reichweite: 0,
    kumuliert_print_umfang_zs: 0,
    kumuliert_print_umfang_tz: 0,

    kumuliert_online_belege: 0,
    kumuliert_online_auflage: 0,
    kumuliert_online_reichweite: 0,
    kumuliert_online_umfang_zs: 0,
    kumuliert_online_umfang_tz: 0,

    total_print_belege: 0,
    total_print_umfang_zs: 0,
    total_print_umfang_tz: 0,
    total_print_auflage: 0,
    total_print_reichweite: 0,

    total_online_belege: 0,
    total_online_umfang_zs: 0,
    total_online_umfang_tz: 0,
    total_online_auflage: 0,
    total_online_reichweite: 0,

    visibility: false,

    overlay: false,
    image_url: [],

  }),

  async created(){

    if (localStorage.product == "Alle" || localStorage.product == ""){
      this.kunde = this.customer_name
    }else{
      this.kunde = localStorage.product
    }

    if (this.kunde.toLowerCase() == "oknoplast"){
        this.kunde = this.kunde.toUpperCase()
    }


    await this.loadData()

  },



  methods:{

    back2ReleaseList(){

      this.$router.push({ path: "/Dashboard" })

    },

    async showBeleg(id){


      // Einstellungen
      var param = {
        table: "images",
        statement: "select",
        type: "list",
        where: "true",
        selector: "releaselists_id",
        value: id,
        short: "true", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {
      }

      console.log("image")
      console.log(id)
      var result = await this.$func.queryAPI(param, data)
      console.log(result)

      // this.image_url = Vue.prototype.$api_upload + result[0]["name"]

      this.image_url = []

      for(var i = 0; i < result.length; i++){
        this.image_url.push(Vue.prototype.$api_upload + result[i]["name"])
      }

      const preview = this.$imagePreview({
        initIndex:0,
        isEnableBlurBackground: false,
        images: this.image_url,
      })

      console.log(preview)

      // this.overlay = true

    },

    async loadData(){

      var extension = ""

      if (localStorage.product == "Alle" || localStorage.product == ""){
        extension = "SELECT * FROM releaselists WHERE kunde='" + localStorage.customer_name + "' AND lower(untertitel) LIKE '%werbung%' AND edatum LIKE '%" + this.id + "%' ORDER BY date_timestamp DESC"
      }else{

        if (localStorage.product == "Oknoplast"){
          extension = "SELECT * FROM releaselists WHERE kunde='" + localStorage.customer_name + "' AND (lower(projekt) NOT LIKE '%wnd%' AND lower(projekt) NOT LIKE '%aluhaus%') AND lower(untertitel) LIKE '%werbung%' AND edatum LIKE '%" + this.id + "%' ORDER BY date_timestamp DESC"
        }else{
          extension = "SELECT * FROM releaselists WHERE kunde='" + localStorage.customer_name + "' AND (lower(projekt) LIKE '%" + localStorage.product.toString().toLowerCase()  + "%' OR lower(untertitel) LIKE '%" + localStorage.product.toString().toLowerCase() + "%') AND lower(untertitel) LIKE '%werbung%' AND edatum LIKE '%" + this.id + "%' ORDER BY date_timestamp DESC"
        }

      }


      // Einstellungen
      var param = {
        table: "releaselists",
        statement: "custom",
        type: "detail",
        where: "false",
        selector: "",
        value: "",
        short: "true", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: extension,
      }

      console.log("extension")
      console.log(extension)


      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {
      }

      var result = await this.$func.queryAPI(param, data)

      // Karte sichbar wenn Daten vorhanden
      if(result.length != 0){
        this.visibility = true
      }

      var objIndex

      // console.log(param.extension)
      // console.log(result)

      for (var i1 = 0; i1 < result.length; i1++) {


        if (result[i1]["gattung"] == "Online-Medien"){
          this.total_online_belege = this.total_online_belege + 1;
        }else{
          this.total_print_belege = this.total_print_belege + 1;
        }


        /*
           0: "Zeitung"
           1-1: "Tageszeitungen"
           1: "Zeitung, Regionalausgabe"
           2: "Anzeigenblätter"
           3: "Publikumszeitschrift"
           4: "Fachzeitschrift"
           5: "Online-Medien"
        */

        var gattung = result[i1]["gattung"]


        if (gattung == "Zeitung" || gattung == "Zeitung, Regionalausgabe" || gattung == "Zeitungen"){
          gattung = "Tageszeitungen"
        }

        if(gattung == "Anzeigen-/Offertenblätter"){
          gattung = "Anzeigenblätter"
        }




        if (!this.gattungen.some(el => el.name === gattung)){
          this.gattungen.push({id: i1, name: gattung, auflage: 0, belege: 0, umfang: 0})
        }

        if (!this.projekte.some(el => el.name === result[i1]["projekt"])){
          this.projekte.push({ id: i1, name: result[i1]["projekt"] })
        }

        this.belege.push({ id: result[i1]["id"], gattung: gattung, medium: result[i1]["medium"], auflage: result[i1]["auflage"], projekt: result[i1]["projekt"], umfang: result[i1]["umfang"], edatum: result[i1]["edatum"], images: result[i1]["images"]})



        //Find index of specific object using findIndex method.
        objIndex = this.gattungen.findIndex((obj => obj.name == gattung));

        console.log(gattung)
        console.log(objIndex)

        this.gattungen[objIndex].belege = this.gattungen[objIndex].belege + 1


        if(!isNaN(Number(result[i1]["umfang"].replace(",",".")))){

          this.total_umfang = this.total_umfang + Number(result[i1]["umfang"].replace(",","."));

          this.gattungen[objIndex].umfang = this.gattungen[objIndex].umfang + Number(result[i1]["umfang"].replace(",","."))
        }
        if(!isNaN(Number(result[i1]["auflage"]))){

          if (result[i1]["gattung"] == "Online-Medien") {
            this.total_online_auflage = this.total_online_auflage + Number(result[i1]["auflage"]);
          }else{
            this.total_print_auflage = this.total_print_auflage + Number(result[i1]["auflage"]);
          }

          this.gattungen[objIndex].auflage = this.gattungen[objIndex].auflage + Number(result[i1]["auflage"])
        }
        if(!isNaN(Number(result[i1]["reichweite"]))){

          if (result[i1]["gattung"] == "Online-Medien") {
            this.total_online_reichweite = this.total_online_reichweite + Number(result[i1]["reichweite"]);
          }else{
            this.total_print_reichweite = this.total_print_reichweite + Number(result[i1]["reichweite"]);
          }
        }


      }


      // Gattungen werden sortiert
      console.log(this.gattungen.sort(this.compare))



      // this.total_umfang = Math.round(this.total_umfang)
      /*
            console.log("total_belege: " + this.total_belege)
            console.log("total_umfang: " + this.total_umfang.toFixed(2))
            console.log("total_auflage: " + this.total_auflage.toLocaleString('de-DE'))
            console.log("reichweite: " + this.reichweite.toLocaleString('de-DE'))

            console.log("gattungen")
            console.log(this.gattungen)

            console.log("produkte")
            console.log(this.produkte)
      */





      if (localStorage.product == "Alle" || localStorage.product == ""){
        extension = "SELECT sum(replace(umfang, ',', '.')) FROM releaselists WHERE gattung!='Online-Medien' AND  customer_id='" + localStorage.customer_id + "' AND edatum LIKE '%" + this.id + "%'"
      }else{

        if (localStorage.product == "Oknoplast"){

          extension = "SELECT sum(replace(umfang, ',', '.')) FROM releaselists WHERE gattung!='Online-Medien' AND (lower(projekt) NOT LIKE lower('%WnD%') AND lower(projekt) NOT LIKE lower('%Aluhaus%')) AND  customer_id='" + localStorage.customer_id + "' AND edatum LIKE '%" + this.id + "%'"

        }else{

          extension = "SELECT sum(replace(umfang, ',', '.')) FROM releaselists WHERE gattung!='Online-Medien' AND lower(projekt) LIKE lower('%" + localStorage.product  + "%') AND (lower(projekt) NOT LIKE lower('%WnD%') AND lower(projekt) NOT LIKE lower('%Aluhaus%')) AND  customer_id='" + localStorage.customer_id + "' AND edatum LIKE '%" + this.id + "%'"

        }

      }



      // total_print_umfang_tz
      param = {
        table: "releaselists",
        statement: "custom",
        type: "",
        where: "false",
        selector: "",
        value: "",
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: extension,
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      data = {
      }

      result = await this.$func.queryAPI(param, data)
      console.log(result)


      this.total_print_umfang_tz = Number(result[0]["sum(replace(umfang, ',', '.'))"]).toLocaleString('de-DE')


    },



    compare( a, b ) {
      if ( a.name < b.name ){
        return -1;
      }
      if ( a.name > b.name ){
        return 1;
      }
      return 0;
    }




  },




}
</script>

<style scoped>

</style>

