<template>

        <v-card
            v-if="visibility"
            elevation="5"
            style="max-width: 800px"


        >
          <v-card-title
              @click="openDetailReleaseList(date)"
              class="text-h5">
           {{$t('releaselists')}} {{ title }}
          </v-card-title>

          <v-card-subtitle></v-card-subtitle>


<!--
          <v-img
              src="../assets/print.png"
              style="
              z-index: 5;
              position: absolute;
              right: 20px;
              top: 20px;
              width: 35px;"
          >

          </v-img>


          <v-img
              src="../assets/details.png"
              style="
              z-index: 5;
              cursor: pointer;
              position: absolute;
              right: 70px;
              top: 20px;
              width: 35px;"
              @click="openDetailReleaseList(date)"
          >

          </v-img>


-->


          <v-card-text
              @click="openDetailReleaseList(date)"
          style="font-size: large;
                 text-align: left;
                 cursor: pointer;
                "
          >

            <div
            style="overflow-inline: auto"
            >
              <div
              style="
              display: inline-block;
              width: 50%"
              >

            <table>
              <tr>
                <th>{{$t('anzahlbelege')}}:</th>
                <th><b>{{belege}}</b></th>
              </tr>
              <tr>
                <th
                style="width: 250px;"
                >{{$t('anzahlprodukteprojekte')}}:</th>
                <th><b>{{produkte.length}}</b></th>
              </tr>
              <tr>
                <th>{{$t('auflagevisits')}}:</th>
                <th><b>{{total_auflage.toLocaleString('de-DE')}}</b></th>
              </tr>
              <tr>
                <th>{{$t('reichweite')}}:</th>
                <th><b>{{reichweite.toLocaleString('de-DE')}}</b></th>
              </tr>
              <tr>
                <th>{{$t('umfang')}}:</th>
                <th><b>{{total_umfang.toFixed(2)}}</b></th>
              </tr>
                <tr>
                    <th>{{$t('aquivalenzwert')}}:</th>
                    <th><b>{{total_aquivalenzwert}}</b></th>
                </tr>
            </table>





              </div>

              <div
              style="
              display: inline-block;
              width: 50%"
              >

                <!--

<div
    style="
              width: 150px;
               display: inline-block;
          "
>
                <vuevectormap
                    style="
              display: inline-block;
              "

                    map="germany"
                    :width="'50'"
                    :height="'100'"
                    :hoverColor="false"
                    :series="series"
                    :regionsSelectable="false"
                    :regionsSelectableOne="false"
                    :zoomButtons="false"
                    :zoomOnScroll="false"

                    :options="{
        // Map options..
        // markers: []
        // markerStyle: {}
        // etc..

      }"
                ></vuevectormap>
</div>

-->

                <div
                    style="
              display: inline-block;

              "
                >
                  <Doughnut
                      style="
              display: inline-block;
              "
                      :chart-options="chartOptions"
                      :chart-data="chartData"
                      :chart-id="chartId"
                      :dataset-id-key="datasetIdKey"
                      :plugins="plugins"
                      :height="150"
                      :width="150"
                  />
                </div>

              </div>

            </div>

            <div
            style="width: 100%"
            >

              <div
              style="
              margin: 20px;
              width: 90%;
              font-size: small;
              text-align: right;
              display: inline-block;
"


              ><div style="
                 display: inline-block;
                 margin-left: 5px;
                width: 10px; height: 10px; background-color: #C9C01C"></div>

                {{ $t("fachzeitschrift") }} <div style="
                 display: inline-block;
                      margin-left: 5px;
                width: 10px; height: 10px; background-color: #1C99C9"></div>

                  {{ $t("zeitungen") }} <div style="
                 display: inline-block;
                      margin-left: 5px;
                width: 10px; height: 10px; background-color: #0B5D7D"></div>

                  {{ $t("sonstige") }} <div style="
                 display: inline-block;
                      margin-left: 5px;
                width: 10px; height: 10px; background-color: #CA0931"></div>
                  {{ $t("online") }}


              </div>

              </div>

          </v-card-text>



        </v-card>

</template>

<script>


/*
window.jQuery = require('jquery');
var $ = window.jQuery;
*/

/*
require('jvectormap');
require('../lib/jquery-jvectormap-world-mill');
*/

import { Doughnut } from 'vue-chartjs/legacy'

import {
  Chart as ChartJS,
  Title,
  Tooltip,

  ArcElement,
  CategoryScale
} from 'chart.js'

ChartJS.register(Title, Tooltip, ArcElement, CategoryScale)



export default {
  name: "CardReleaseList",

  components: { Doughnut },

  data: () => ({

    /*
    markers: [
      { name: "Reutlingen", coords: [48.5069389, 9.2038043] },
    ],
    */
    width: 250,

    markerLabelStyle: {
      backgroundColor: 'black',
    },

    datasetIdKey: "1",

    series: {
      regions: [
        {
          attribute: "fill",
/*
          legend: {
            title: "Play around with series",
          },
*/
          scale: {
            scale4: "rgba(202, 9, 49, 1)",
            scale3: "rgba(202, 9, 49, 0.7)",
            scale2: "rgba(202, 9, 49, 0.4)",
            scale1: "rgba(202, 9, 49, 0.1)",
            scale0: "#DCDCDC",
          },


          values: {
            'DE-BW': "scale0",
            'DE-BY': "scale0",
            'DE-BE': "scale0",
            'DE-BB': "scale0",
            'DE-HB': "scale0",
            'DE-HH': "scale0",
            'DE-HE': "scale0",
            'DE-MV': "scale0",
            'DE-NI': "scale0",
            'DE-NW': "scale0",
            'DE-RP': "scale0",
            'DE-SL': "scale0",
            'DE-SN': "scale0",
            'DE-ST': "scale0",
            'DE-SH': "scale0",
            'DE-TH': "scale0",
          },


        },
      ],
    },



   title: '',
   gattungen: [],
   gattung_counter:[
     {
       fachzeitschriften: 0,
       zeitungen: 0,
       sonstige: 0,
       online: 0,
     }]
   ,
    state_total_counter: 0,

    state_counter:[
      {
        BW: 0,
        BY: 0,
        BE: 0,
        BB: 0,
        HB: 0,
        HH: 0,
        HE: 0,
        MV: 0,
        NI: 0,
        NW: 0,
        RP: 0,
        SL: 0,
        SN: 0,
        ST: 0,
        SH: 0,
        TH: 0,
      }]
    ,

/*
    ["1516", "DE-BW"],
    ["2333", "DE-BY"],
    ["2334", "DE-BE"],
    ["3562", "DE-BB"],
    ["1518", "DE-HB"],
    ["1520", "DE-HH"],
    ["1517", "DE-HE"],
    ["3312", "DE-MV"],
    ["6321", "DE-NI"],
    ["1515", "DE-NW"],
    ["2331", "DE-RP"],
    ["2332", "DE-SL"],
    ["2336", "DE-SN"],
    ["2335", "DE-ST"],
    ["942",  "DE-SH"],
    ["1519", "DE-TH"]
    */
   produkte: [],
   belege: 0,
   total_umfang: 0,
   total_auflage: 0,
   reichweite: 0,
   total_aquivalenzwert: 0,




   visibility: false,
// online, zeitungen, fachzeitschrift, sonstige
    chartData: {
      labels: ['','','',''],
      datasets: [
        {
        backgroundColor: ['#CA0931', '#1C99C9', '#C9C01C', '#0B5D7D'],
        data: [20, 20, 40, 20]
        }
      ],

    },

    chartOptions: {
      responsive: true,
      maintainAspectRatio: false,

        plugins: {
            legend: {
                display: false
            },
        }

    }

  }),


  async created(){

    this.$moment().locale("de")

    this.title = this.translateMonth(this.$moment(this.date, "MM.YYYY").format("MMMM")) + " " + this.$moment(this.date, "MM.YYYY").format("YYYY")

    await this.loadData()

  },

  mounted(){
  },


  methods:{

    openDetailReleaseList(id){
      this.$router.push({ path: "/DetailReleaseList/" + id })
    },

    translateMonth(englishname){

      var output

      switch(englishname) {

        case "January":
          output = "Januar"
          break;

        case "February":
          output = "Februar"
          break;

        case "March":
          output = "März"
          break;

        case "April":
          output = "April"
          break;

        case "May":
          output = "Mai"
          break;

        case "June":
          output = "Juni"
          break;

        case "July":
          output = "Juli"
          break;

        case "August":
          output = "August"
          break;

        case "September":
          output = "September"
          break;

        case "October":
          output ="Oktober"
          break;

        case "November":
          output = "November"
          break;

        case "December":
          output = "Dezember"
          break;
      }


      return output



    },

    async loadData(){

      var extension = ""

      if (localStorage.product == "Alle" || localStorage.product == ""){

        extension = "SELECT * FROM releaselists WHERE kunde='" + localStorage.customer_name + "' AND lower(untertitel) NOT LIKE '%infobeleg%' AND lower(untertitel) NOT LIKE '%werbung%' AND edatum LIKE '%" + this.date + "%'"

      }else{


        if (localStorage.product == "Oknoplast"){

          extension = "SELECT * FROM releaselists WHERE kunde='" + localStorage.customer_name + "' AND lower(untertitel) NOT LIKE '%infobeleg%' AND lower(untertitel) NOT LIKE '%werbung%' AND (lower(projekt) NOT LIKE '%wnd%' AND lower(projekt) NOT LIKE '%aluhaus%') AND edatum LIKE '%" + this.date + "%'"

        }else{

          extension = "SELECT * FROM releaselists WHERE kunde='" + localStorage.customer_name + "' AND lower(untertitel) NOT LIKE '%infobeleg%' AND lower(untertitel) NOT LIKE '%werbung%' AND (lower(projekt) LIKE '%" + localStorage.product.toString().toLowerCase()  + "%' OR lower(untertitel) LIKE '%" + localStorage.product.toString().toLowerCase()  + "%') AND edatum LIKE '%" + this.date + "%'"

        }

      }


      // Einstellungen
      var param = {
        table: "releaselists",
        statement: "custom",
        type: "detail",
        where: "false",
        selector: "",
        value: "",
        short: "true", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: extension,
      }

      console.log("extension")
      console.log(extension)


      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {
      }

      var result = await this.$func.queryAPI(param, data)

      // Karte sichbar wenn Daten vorhanden
      if(result.length != 0){
        this.visibility = true
      }

      console.log(param.extension)
      console.log("AUSGABE")
      console.log(result)

      for (var i1 = 0; i1 < result.length; i1++) {

        this.belege = this.belege + 1;

         /*
            0: "Zeitung"
            1: "Zeitung, Regionalausgabe"
            2: "Anzeigenblätter"
            3: "Publikumszeitschrift"
            4: "Fachzeitschrift"
            5: "Online-Medien"
         */



        /*
            ["1516", "DE-BW"],
            ["2333", "DE-BY"],
            ["2334", "DE-BE"],
            ["3562", "DE-BB"],
            ["1518", "DE-HB"],
            ["1520", "DE-HH"],
            ["1517", "DE-HE"],
            ["3312", "DE-MV"],
            ["6321", "DE-NI"],
            ["1515", "DE-NW"],
            ["2331", "DE-RP"],
            ["2332", "DE-SL"],
            ["2336", "DE-SN"],
            ["2335", "DE-ST"],
            ["942",  "DE-SH"],
            ["1519", "DE-TH"]
        */


        // console.log("state: " + result[i1]["state"])


            switch(result[i1]["state"]){

              case "Nordrhein-Westfalen":
                this.state_counter[0]["NW"] = this.state_counter[0]["NW"] + 1
                this.state_total_counter++
                break;
              case "Bayern":
                this.state_counter[0]["BY"] = this.state_counter[0]["BY"] + 1
                this.state_total_counter++
                break;
              case "Baden-Württemberg":
                this.state_counter[0]["BW"] =  this.state_counter[0]["BW"] + 1
                this.state_total_counter++
                break;
              case "Niedersachsen":
                this.state_counter[0]["NI"] =  this.state_counter[0]["NI"] + 1
                this.state_total_counter++
                break;
              case "Hessen":
                this.state_counter[0]["HE"] =  this.state_counter[0]["HE"] + 1
                this.state_total_counter++
                break;
              case "Rheinland-Pfalz":
                this.state_counter[0]["RP"] =  this.state_counter[0]["RP"] + 1
                this.state_total_counter++
                break;
              case "Sachsen":
                this.state_counter[0]["SN"] =  this.state_counter[0]["SN"] + 1
                this.state_total_counter++
                break;
              case "Berlin":
                this.state_counter[0]["BE"] =  this.state_counter[0]["BE"] + 1
                this.state_total_counter++
                break;
              case "Brandenburg":
                this.state_counter[0]["BB"] =  this.state_counter[0]["BB"] + 1
                this.state_total_counter++
                break;
              case "Sachsen-Anhalt":
                this.state_counter[0]["ST"] =  this.state_counter[0]["ST"] + 1
                this.state_total_counter++
                break;
              case "Thüringen":
                this.state_counter[0]["TH"] =  this.state_counter[0]["TH"] + 1
                this.state_total_counter++
                break;
              case "Hamburg":
                this.state_counter[0]["HH"] =  this.state_counter[0]["HH"] + 1
                this.state_total_counter++
                break;
              case "Mecklenburg-Vorpommern":
                this.state_counter[0]["MV"] =  this.state_counter[0]["MV"] + 1
                this.state_total_counter++
                break;
              case "Saarland":
                this.state_counter[0]["SL"] =  this.state_counter[0]["SL"] + 1
                this.state_total_counter++
                break;
              case "Bremen":
                this.state_counter[0]["HB"] =  this.state_counter[0]["HB"] + 1
                this.state_total_counter++
                break;

            }





            switch(result[i1]["gattung"]){

              case "Zeitung":
                this.gattung_counter[0]["zeitungen"] = this.gattung_counter[0]["zeitungen"] + 1
                break;
              case "Zeitung, Regionalausgabe":
                this.gattung_counter[0]["zeitungen"] = this.gattung_counter[0]["zeitungen"] + 1
                break;
              case "Tageszeitungen":
                this.gattung_counter[0]["zeitungen"] = this.gattung_counter[0]["zeitungen"] + 1
                break;
              case "Anzeigenblätter":
                this.gattung_counter[0]["sonstige"] =  this.gattung_counter[0]["sonstige"] + 1
                break;
              case "Publikumszeitschrift":
                this.gattung_counter[0]["sonstige"] =  this.gattung_counter[0]["sonstige"] + 1
                break;
              case "Fachzeitschrift":
                this.gattung_counter[0]["fachzeitschriften"] =  this.gattung_counter[0]["fachzeitschriften"] + 1
                break;
              case "Online-Medien":
                this.gattung_counter[0]["online"] =  this.gattung_counter[0]["online"] + 1
                break;
              default:
                this.gattung_counter[0]["sonstige"] =  this.gattung_counter[0]["sonstige"] + 1
                break;
            }


        /*
          fachzeitschriften: 0,
          zeitungen: 0,
          sonstige: 0,
          online: 0,
        */



        if (this.gattungen.includes(result[i1]["gattung"]) == false){
        this.gattungen.push(result[i1]["gattung"])
        }

        if (this.produkte.includes(result[i1]["projekt"]) == false){
          this.produkte.push(result[i1]["projekt"])
        }

        if(!isNaN(Number(result[i1]["umfang"].replace(",",".")))){
          this.total_umfang = this.total_umfang + Number(result[i1]["umfang"].replace(",","."));
        }
        if(!isNaN(Number(result[i1]["auflage"]))){
          this.total_auflage = this.total_auflage + Number(result[i1]["auflage"]);
        }
        if(!isNaN(Number(result[i1]["reichweite"]))){
          this.reichweite = this.reichweite + Number(result[i1]["reichweite"]);
        }

          if(!isNaN(Number(result[i1]["aäwg"]))){
              this.total_aquivalenzwert = this.total_aquivalenzwert + Number(result[i1]["aäwg"]);
          }


      }


        this.total_aquivalenzwert = this.total_aquivalenzwert.toLocaleString('de-DE')

        this.total_aquivalenzwert = this.total_aquivalenzwert + " EUR"

      this.reichweite = Number(this.total_auflage) * 2.5

      this.reichweite = this.reichweite.toFixed(0)

      this.reichweite = Number(this.reichweite).toLocaleString('de-DE').replace(",", ".")

      // this.total_umfang = Math.round(this.total_umfang)
/*
      console.log("belege: " + this.belege)
      console.log("total_umfang: " + this.total_umfang.toFixed(2))
      console.log("total_auflage: " + this.total_auflage.toLocaleString('de-DE'))
      console.log("reichweite: " + this.reichweite.toLocaleString('de-DE'))

      console.log("gattungen")
      console.log(this.gattungen)

      console.log("produkte")
      console.log(this.produkte)
*/

          this.chartData["datasets"][0]["data"][0] = this.gattung_counter[0]["online"]
          this.chartData["datasets"][0]["data"][1] = this.gattung_counter[0]["zeitungen"]
          this.chartData["datasets"][0]["data"][2] = this.gattung_counter[0]["fachzeitschriften"]
          this.chartData["datasets"][0]["data"][3] = this.gattung_counter[0]["sonstige"]

        // online, zeitungen, fachzeitschrift, sonstige

this.chartData["labels"][0] = this.$t("online")
this.chartData["labels"][1] = this.$t("zeitungen")
this.chartData["labels"][2] = this.$t("fachzeitschrift")
this.chartData["labels"][3] = this.$t("sonstige")


      console.log("this.state_counter")
      console.log(this.state_total_counter)
      console.log(this.state_counter)

      console.log(this.series)
      /*
          ["1516", "DE-BW"],
          ["2333", "DE-BY"],
          ["2334", "DE-BE"],
          ["3562", "DE-BB"],
          ["1518", "DE-HB"],
          ["1520", "DE-HH"],
          ["1517", "DE-HE"],
          ["3312", "DE-MV"],
          ["6321", "DE-NI"],
          ["1515", "DE-NW"],
          ["2331", "DE-RP"],
          ["2332", "DE-SL"],
          ["2336", "DE-SN"],
          ["2335", "DE-ST"],
          ["942",  "DE-SH"],
          ["1519", "DE-TH"]
      */

      if(this.state_counter[0]["BW"] != 0){
        this.series["regions"][0]["values"]["DE-BW"] = this.calcScale(this.state_counter[0]["BW"])
      }
      if(this.state_counter[0]["BY"] != 0){
        this.series["regions"][0]["values"]["DE-BY"] = this.calcScale(this.state_counter[0]["BY"])
      }
      if(this.state_counter[0]["BE"] != 0){
        this.series["regions"][0]["values"]["DE-BE"] = this.calcScale(this.state_counter[0]["BE"])
      }
      if(this.state_counter[0]["BB"] != 0){
        this.series["regions"][0]["values"]["DE-BB"] = this.calcScale(this.state_counter[0]["BB"])
      }
      if(this.state_counter[0]["HB"] != 0){
        this.series["regions"][0]["values"]["DE-HB"] = this.calcScale(this.state_counter[0]["HB"])
      }
      if(this.state_counter[0]["HH"] != 0){
        this.series["regions"][0]["values"]["DE-HH"] = this.calcScale(this.state_counter[0]["HH"])
      }
      if(this.state_counter[0]["HE"] != 0){
        this.series["regions"][0]["values"]["DE-HE"] = this.calcScale(this.state_counter[0]["HE"])
      }
      if(this.state_counter[0]["MV"] != 0){
        this.series["regions"][0]["values"]["DE-MV"] = this.calcScale(this.state_counter[0]["MV"])
      }
      if(this.state_counter[0]["NI"] != 0){
        this.series["regions"][0]["values"]["DE-NI"] = this.calcScale(this.state_counter[0]["NI"])
      }
      if(this.state_counter[0]["NW"] != 0){
        this.series["regions"][0]["values"]["DE-NW"] = this.calcScale(this.state_counter[0]["NW"])
      }
      if(this.state_counter[0]["RP"] != 0){
        this.series["regions"][0]["values"]["DE-RP"] = this.calcScale(this.state_counter[0]["RP"])
      }
      if(this.state_counter[0]["SL"] != 0){
        this.series["regions"][0]["values"]["DE-SL"] = this.calcScale(this.state_counter[0]["SL"])
      }
      if(this.state_counter[0]["SN"] != 0){
        this.series["regions"][0]["values"]["DE-SN"] = this.calcScale(this.state_counter[0]["SN"])
      }
      if(this.state_counter[0]["ST"] != 0){
        this.series["regions"][0]["values"]["DE-ST"] = this.calcScale(this.state_counter[0]["ST"])
      }
      if(this.state_counter[0]["SH"] != 0){
        this.series["regions"][0]["values"]["DE-SH"] = this.calcScale(this.state_counter[0]["SH"])
      }
      if(this.state_counter[0]["TH"] != 0){
        this.series["regions"][0]["values"]["DE-TH"] = this.calcScale(this.state_counter[0]["TH"])
      }


    },


    calcScale(number){

      var calc = (Number(this.state_total_counter) * Number(number)) / 100

      // console.log("calcScale")
      // console.log(calc)

      if (calc < 25){
        return  "scale2"
      }else if(calc < 50){
        return  "scale2"
      }else if(calc < 75){
        return  "scale3"
      }else if(calc < 90){
        return  "scale4"
      }


    }

  },




  props: {

    date: String,

    chartId: {
      type: String,
      default: 'doughnut-chart'
    },
    width: {
      type: Number,
      default: 150
    },
    height: {
      type: Number,
      default: 150
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => {
      }
    },
    plugins: {
      type: Array,
      default: () => []
    }

  }

}
</script>

<style lang="css">

svg {
  -ms-touch-action: none;
  touch-action: none;
}
image, text, .jvm-zoomin, .jvm-zoomout {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.jvm-container {
  -ms-touch-action: none;
  touch-action: none;
  position: relative;
  overflow: hidden;
  height: 100%;
  width: 100%}
.jvm-tooltip {
  border-radius: 3px;
  background-color: #6f6f77;
  font-family: sans-serif, Verdana;
  font-size: smaller;
  box-shadow: 1px 2px 12px rgba(0, 0, 0, 0.2);
  padding: 3px 5px;
  white-space: nowrap;
  position: absolute;
  display: none;
  color: #FFF;
}
.jvm-tooltip.active {
  display: block;
}
.jvm-zoom-btn {
  border-radius: 3px;
  background-color: #292929;
  padding: 3px;
  box-sizing: border-box;
  position: absolute;
  line-height: 10px;
  cursor: pointer;
  color: #FFF;
  height: 15px;
  width: 15px;
  left: 10px;
}
.jvm-zoom-btn.jvm-zoomout {
  top: 30px;
}
.jvm-zoom-btn.jvm-zoomin {
  top: 10px;
}
.jvm-series-container {
  right: 15px;
  position: absolute;
}
.jvm-series-container.jvm-series-h {
  bottom: 15px;
}
.jvm-series-container.jvm-series-v {
  top: 15px;
}
.jvm-series-container .jvm-legend {
  background-color: #fff;
  border: 1px solid #e5e7eb;
  margin-left: .75rem;
  border-radius: .25rem;
  border-color: #e5e7eb;
  padding: .6rem;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  float: left;
}
.jvm-series-container .jvm-legend .jvm-legend-title {
  line-height: 1;
  border-bottom: 1px solid #e5e7eb;
  padding-bottom: .5rem;
  margin-bottom: .575rem;
  text-align: left;
}
.jvm-series-container .jvm-legend .jvm-legend-inner {
  overflow: hidden;
}
.jvm-series-container .jvm-legend .jvm-legend-inner .jvm-legend-tick {
  overflow: hidden;
  min-width: 40px;
}
.jvm-series-container .jvm-legend .jvm-legend-inner .jvm-legend-tick:not(:first-child) {
  margin-top: .575rem;
}
.jvm-series-container .jvm-legend .jvm-legend-inner .jvm-legend-tick .jvm-legend-tick-sample {
  border-radius: 4px;
  margin-right: .65rem;
  height: 16px;
  width: 16px;
  float: left;
}
.jvm-series-container .jvm-legend .jvm-legend-inner .jvm-legend-tick .jvm-legend-tick-text {
  font-size: 12px;
  text-align: center;
  float: left;
}
.jvm-line[animation="true"] {
  -webkit-animation: jvm-line-animation 10s linear forwards infinite;
  animation: jvm-line-animation 10s linear forwards infinite;
}
@-webkit-keyframes jvm-line-animation {
  from {
    stroke-dashoffset: 250;
  }
}@keyframes jvm-line-animation {
   from {
     stroke-dashoffset: 250;
   }
 }

</style>
