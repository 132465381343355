import Vue from "vue";

export const func = {


    async queryAPI(param, data) {

// table, statement, parameter, jobject

// $table         DB name
// $statement     SELECT, INSERT, UPDATE, DELETE,

// $type          detail, list (Bei SELECT) --> "detail" erfordert id
// $limit         Abfrage mit Limit
// $id            WHERE id

// ---> Geziehlte abfrage oder schreiben
// $where         true
// $selector      Spalte
// $value         wert der Spalte

// $filter        true ---> zusätzlich angewandte filter funktion ... AND $filterfield LIKE %$filtervalue%
// $filterfield
// $filtervalue

// $extension     Erweiterung am ende des SQL Befehls z.B. ORDER BY Colum ASC


        var request = "param=" + encodeURIComponent(JSON.stringify(param)) + "&data=" + encodeURIComponent(JSON.stringify(data));

        console.log(Vue.prototype.$api_endpoint + "?" + request)

        try {

            await Vue.prototype.$axios
                .get(Vue.prototype.$api_endpoint + "?" + request)
                .then(response => (this.responseText = response))
            
            return this.responseText["data"]

        }catch{

            return {"status": "404"}

        }

    },

    async queryAPITest() {

        try {

            await Vue.prototype.$axios
                .get(Vue.prototype.$api_endpoint + "?n=test")
                .then(response => (this.responseText = response))

            return this.responseText["data"]

        }catch{

            return {"status": "404"}

        }

    },

    isMobile() {

        if( screen.width <= 760 ) {
            return true;
        }
        else {
            return false;
        }

    }



}
