<template>
<div>

  <div
  style="margin: 30px"
  >

  <div
  style="margin: 20px"
  ><h2>MEDIUS LISTE IMPORTIEREN</h2></div>

  <input
      style="margin: 20px"
      id="file" ref="doc" type="file" @change="readFile" />

  <div
  style="margin: 20px"
  >
    <v-btn
        elevation="2"
        id="upload-button" @click="uploadFile()">Upload</v-btn>
  </div>





    <div
        style="
        margin-top: 100px;
        margin-left: 20px;"
    >

      <div
      ><h2>Stadtnamen GPS Abgleich</h2></div>
      <div
          style="
        margin-top: 50px;
        "
      ></div>
      <v-btn
          elevation="2"
          id="upload-button" @click="checkGeoData()">Start
      </v-btn>
    </div>




    <div
        style="
        margin-top: 100px;
        margin-left: 20px;"
    >

      <div
      ><h2>Bundesland zuordnen</h2></div>
      <div
          style="
        margin-top: 50px;
        "
      ></div>
      <v-btn
          elevation="2"
          id="upload-button" @click="assignState()">Start
      </v-btn>
    </div>





  </div>






  <v-overlay :value="overlay">
    <v-progress-circular
        indeterminate
        size="64"
    ></v-progress-circular>
  </v-overlay>




</div>
</template>

<script>

import Vue from "vue";
// https://masteringjs.io/tutorials/vue/file

export default {
  name: "Upload",

  data () {
    return {

      snackbar: false,
      snackbar_title: 'Gespeichert!',
      snackbar_color: 'red',
      timeout: 1000,

      file: '',
      overlay: false,

      filedata: [],

    }
  },



  created() {

  },

  mounted(){

  },


  methods: {

    readFile() {
      this.file = this.$refs.doc.files[0];
      const reader = new FileReader();

      // this.file.name.includes(".txt")

      this.content = "check the console for file output";
      reader.onload = (res) => {

        // console.log(res.target.result);

        var lines = res.target.result.split('\n');

        for (var line = 0; line < lines.length; line++) {
          this.filedata[line] = lines[line].split("\t")
        }


      };
      reader.onerror = (err) => console.log(err);
      reader.readAsText(this.file, 'ISO-8859-1');

    },


    async uploadFile() {

      console.log(this.filedata)

      /*

      0: "Medium"
      1: "Ort"
      2: "E.-Datum"
      3: "Titel"
      4: "Untertitel"
      5: "Auflage/PV"
      6: "Reichweite/PI"
      7: "AÄW ung."
      8: "AÄW g."
      9: "PRW-Faktor"
      10: "PR Wert"
      11: "journ. Form"
      12: "Thema"
      13: "Medienart"
      14: "Liste"
      15: "Tenor"
      16: "Umfang"
      17: "exklusiv"
      18: "bezahlt"
      19: "Kommentar"
      20: "Redakteur"
      21: "Ressort"
      22: "Scans"
      23: "Gattung"
      24: "Register"
      25: "allg. Beurteilung"
      26: "Kunde"
      27: "Projekt"
      28: "Seitennummer"
      29: "Erscheinungsweise"
      30: "Anhang"
      31: "RecordStatus"

      */


      if (this.filedata.length == 0) {
        alert("Datei auswählen!")
        return
      }


      if (this.filedata[0][0] != "Medium") {
        alert("Datei Fehlerhaft - Bitte Datei prüfen!")
        return
      }

      this.overlay = true


      for (var i1 = 0; i1 < this.filedata.length; i1++) {

        var customer_id = ""

        if (i1 != 0) {

          await this.AddCity(this.filedata[i1])
          await this.AddMedium(this.filedata[i1])

          customer_id = await this.AddCustomer(this.filedata[i1])

          await this.AddProject(this.filedata[i1], customer_id)
          await this.AddRelease(this.filedata[i1], customer_id)

        }


      }


      this.overlay = false


    },

    async AddCity(dataset) {


      if (dataset[1] == "") {
        return
      }


      // Einstellungen
      var param = {
        table: "city",
        statement: "select",
        type: "detail",
        where: "true",
        selector: "name",
        value: dataset[1],
        short: "true", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {}

      var result = await this.$func.queryAPI(param, data)
      console.log(result)

      if (result.length != 0) {
        // console.log("Datensatz schon vorhanden!")
        return
      }

      // console.log("Datensatz wird angelegt")

      // Einstellungen
      param = {
        table: "city",
        statement: "insert",
        type: "",
        where: "false",
        selector: "",
        value: "",
        short: "true", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      data = {
        name: dataset[1]
      }

      result = await this.$func.queryAPI(param, data)
      // console.log(result)

    },

    async AddMedium(dataset) {


      if (dataset[0] == "") {
        return
      }


      // Einstellungen
      var param = {
        table: "medium",
        statement: "select",
        type: "detail",
        where: "true",
        selector: "name",
        value: dataset[0],
        short: "true", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {}

      var result = await this.$func.queryAPI(param, data)
      console.log(result)

      if (result.length != 0) {
        // console.log("Datensatz schon vorhanden!")
        return
      }

      // console.log("Datensatz wird angelegt")

      // Einstellungen
      param = {
        table: "medium",
        statement: "insert",
        type: "",
        where: "false",
        selector: "",
        value: "",
        short: "true", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      data = {
        name: dataset[0],
        ort: dataset[1],
        gattung:  dataset[23],
        auflage: dataset[5],
        reichweite: dataset[6],
        erscheinungsweise: dataset[29],
      }

      result = await this.$func.queryAPI(param, data)
      // console.log(result)

    },

    async AddCustomer(dataset) {

      if (dataset[26] == "") {
        return
      }

      // Einstellungen
      var param = {
        table: "customer",
        statement: "select",
        type: "detail",
        where: "true",
        selector: "name",
        value: dataset[26],
        short: "true", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {}

      var result = await this.$func.queryAPI(param, data)
      console.log(result)

      if (result.length != 0) {
        // console.log("Datensatz schon vorhanden!")

        return result[0]["id"]
      }

      // console.log("Datensatz wird angelegt")

      // Einstellungen
      param = {
        table: "customer",
        statement: "insert",
        type: "",
        where: "false",
        selector: "",
        value: "",
        short: "true", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      data = {
        name: dataset[26],
      }

      result = await this.$func.queryAPI(param, data)
      return result["value"]


    },

    async AddProject(dataset, customer_id) {

      if (dataset[27] == "") {
        return
      }

      // Einstellungen
      var param = {
        table: "project",
        statement: "select",
        type: "detail",
        where: "true",
        selector: "name",
        value: dataset[27],
        short: "true", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {}

      var result = await this.$func.queryAPI(param, data)
      console.log(result)

      if (result.length != 0) {
        // console.log("Datensatz schon vorhanden!")
        return
      }

      // console.log("Datensatz wird angelegt")

      // Einstellungen
      param = {
        table: "project",
        statement: "insert",
        type: "",
        where: "false",
        selector: "",
        value: "",
        short: "true", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      data = {
        name: dataset[27],
        customer_name: dataset[26],
        customer_id: customer_id,
      }

      result = await this.$func.queryAPI(param, data)

    },

    async AddRelease(dataset, customer_id) {

      if (dataset[0] == "") {
        return
      }

      // Einstellungen
      var param = {
        table: "releaselists",
        statement: "select",
        type: "list",
        where: "false",
        selector: "",
        value: "",
        short: "true", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "WHERE medium='" + dataset[0] + "' AND edatum='" + dataset[2] + "' AND projekt='" + dataset[27] + "' AND ort='" + dataset[1] + "'",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {}

      var result = await this.$func.queryAPI(param, data)
      console.log(result)

      if (result.length != 0) {
        // console.log("Datensatz schon vorhanden!")
        return
      }

      // console.log("Datensatz wird angelegt")

      // Einstellungen
      param = {
        table: "releaselists",
        statement: "insert",
        type: "",
        where: "false",
        selector: "",
        value: "",
        short: "true", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      data = {
        medium: dataset[0],
        ort: dataset[1],
        edatum: dataset[2],
        date_timestamp: this.$moment(dataset[2], "DD.MM.YYYY").format('YYYY-MM-DD'),
        title: dataset[3],
        untertitel: dataset[4],
        auflage: dataset[5],
        reichweite: dataset[6],
        thema: dataset[12],
        medienart: dataset[13],
        liste: dataset[14],
        tenor: dataset[15],
        umfang: dataset[16],
        kommentar: dataset[19],
        redakteur: dataset[20],
        scans: dataset[22],
        gattung: dataset[23],
        register: dataset[24],
        beurteilung: dataset[25],
        kunde: dataset[26],
        projekt: dataset[27],
        // size: dataset[2],
        // date: dataset[2],
        listno: dataset[2],
        seitennummer: dataset[28],
        erscheinungsweise: dataset[29],
        customer_id: customer_id,

      }

      result = await this.$func.queryAPI(param, data)
      // console.log(result)

    },


    async assignState(){

      // Einstellungen
      var param = {
        table: "releaselists",
        statement: "custom",
        type: "",
        where: "false",
        selector: "",
        value: "",
        short: "true", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        // extension: "SELECT * FROM releaselists WHERE state=''",
        extension: "SELECT * FROM releaselists", // Überscheit auch vorhande Einträge, falls Bundesland nicht richtig war
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {}

      var result = await this.$func.queryAPI(param, data)
      console.log(result)


      for (var i1 = 0; i1 < result.length; i1++) {
        await this.addState2Releaselists(result[i1]["id"], result[i1]["ort"])
      }





    },


    async addState2Releaselists(id, ort){

      // Einstellungen
      var param = {
        table: "city",
        statement: "select",
        type: "detail",
        where: "true",
        selector: "name",
        value: ort,
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {}

      var result = await this.$func.queryAPI(param, data)
      console.log(result)

      console.log("ort")
      console.log(ort)

      if(result.length == 0){
        return
      }


      var state = result[0]["state"]


      if(state == "Deutschland" || state == ""){
        return
      }


      // Einstellungen
      param = {
        table: "releaselists",
        statement: "update",
        type: "",
        where: "true",
        selector: "id",
        value: id,
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      data = {
        state: state
      }

      result = await this.$func.queryAPI(param, data)
      console.log(result)




    },






    async checkGeoData(){
      console.log("checkGeoData")

      // Einstellungen
      var param = {
        table: "city",
        statement: "select",
        type: "list",
        where: "false",
        selector: "",
        value: "",
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "WHERE lat='' AND lng=''",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {
      }

      var result = await this.$func.queryAPI(param, data)
      console.log(result)


      for (var i = 0; i < result.length; i++) {
        console.log(result[i]["name"])
      //  await this.setGeoData(result[i]["name"])
      }

     // Test
     // await this.setGeoData("München")

    },



    async setGeoData(city) {
      console.log("setGeoData")

      Vue.$geocoder.setDefaultMode('address');      // this is default

      var addressObj = {
        address_line_1: '',
        address_line_2: '',
        city: city,
        state: '',                      // province also valid
        zip_code: '',                   // postal_code also valid
        country: 'Deutschland'
      }


        Vue.$geocoder.send(addressObj, response => {

        console.log("$geocoder - response")
        console.log(response)

        /*
        if (response["results"][0]["formatted_address"] == "Deutschland") {
          return
        }
        */

          console.log("city")
          console.log(city)

        this.writeGeoData(city, response["results"][0]["geometry"]["location"]["lat"], response["results"][0]["geometry"]["location"]["lng"], response["results"][0]["address_components"][3]["long_name"])

        // return response

        // this.lat = response["results"][0]["geometry"]["location"]["lat"]
        // this.lng = response["results"][0]["geometry"]["location"]["lng"]

        /*
        console.log("lat: " + response["results"][0]["geometry"]["location"]["lat"])
        console.log("lng: " + response["results"][0]["geometry"]["location"]["lng"])
        console.log("formatted_address: " + response["results"][0]["formatted_address"])
        console.log("formatted_address: " + response["results"][0]["address_components"][3]["long_name"])
        */

      });



      // var response = await Vue.$geocoder.send(addressObj)
      // console.log(response)


    },


    async writeGeoData(city, lat, lng, state){


      // Einstellungen
      var param = {
        table: "city",
        statement: "update",
        type: "",
        where: "true",
        selector: "name",
        value: city,
        short: "true", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {
        lat: lat,
        lng: lng,
        state: state
      }

      var result = await this.$func.queryAPI(param, data)
      console.log(result)


    }




  }

}
</script>

<style scoped>

</style>
