<template>
<div
>
  <!--:hint="`${select.text} ${select.value}`"-->
  <v-select

      outlined
      v-model="select"

      :items="items"

      :label="label"
      persistent-hint
      return-object
      clearable

      @input="handleInput"

  ></v-select>




</div>
</template>

<script>
export default {
  name: "CustomSelect",

  data () {
    return {
      items: [],
      select: "",
    }
  },

  async created() {

    await this.loadData()

  },


  watch:{

    selected(){

      console.log("selected")

      this.select = this.selected

    }



  },


  methods:{

    handleInput () {

      console.log("handleInput")

      if(this.select == null){

        this.$emit('input', "")
      }else{
        console.log(this.select)
        this.$emit('input', this.select)
      }

    },


    async loadData(){

      this.data = []

      // Einstellungen
      var param = {
        table: this.table,
        statement: "custom",
        type: "list",
        where: "false",
        selector: "",
        value: "",
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: this.extension,
      }

      var data = {}
      var result = await this.$func.queryAPI(param, data)

      this.items = []

      for (var i1 = 0; i1 < result.length; i1++) {

        this.items[i1] = result[i1][this.field_value]

        // Select Eintrag
        if(this.selected == result[i1][this.field_value]){
          this.select = result[i1][this.field_value]
        }

      }

      this.select = this.selected


    },

  },


  props:{

    value: String,

    table: String,

    label: {
      type: String,
      default: 'Bitte auswählen!'
    },

    selected: {
      type: String,
      default: ''
    },


    extension: {
      type: String,
      default: ''
    },

    field_text: String,
    field_value: String,
    // show_value: Boolean

  },


  model: {
    prop: 'value',
    event: 'input'
  },

}
</script>

<style scoped>

</style>
