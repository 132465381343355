<template>
<div>

  <List
      v-bind:headers="headers"
      v-bind:formview="formview"
      v-bind:table="table"
      v-bind:addbutton="true"
      v-bind:default_limit="'500'"
      v-bind:default_sort="'date_timestamp'"
      v-bind:default_sort_typ="'DESC'"
      v-bind:extension="''"
  ></List>

</div>
</template>

<script>

import List from '@/components/ListDefault'

export default {
  name: "ListRelease",

  components: {
    List,
  },

  created() {

  },

  data () {
    return {
      search: '',
      formview: 'FormRelease',
      table: 'releaselists',
    }
  },

  computed: {
    headers () {
      return [
        { text: 'Datum', align: 'start', sortable: true, value: 'edatum' },
        { text: 'Medium', value: 'medium' },
        { text: 'Ort', value: 'ort' },
        { text: 'Kunde', value: 'kunde' },
        { text: 'Projekt', value: 'projekt' },
        { text: 'Umfang', value: 'umfang' },
        { text: 'Auflage/Visits', value: 'auflage' },
        { text: 'Äquivalenzwert', value: 'aäwg' },
        { text: 'Gattung', value: 'gattung' },
        { text: 'Belege', value: 'belege' },
        { text: '', value: 'edit' },
      ]
    },
  },


}
</script>

<style scoped>

</style>
