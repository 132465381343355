<template>
  <div>
    <img

        :src="path"
        :alt="description"
        style="
      float: left;

      margin: 20px;
      margin-left: 0px;

      width: 150px;
      height: 110px;

      cursor: pointer;
      "
        v-on:click="btnZoom"
    >

    <cImagePreview
        v-if="preview"
        v-bind:id="id"
        v-bind:table="table"
        v-bind:value="value"
        @close="setPreview"
    ></cImagePreview>

  </div>

</template>

<script>

import cImagePreview from "@/components/cImagePreview";
import Vue from "vue";

export default {
  name: "cImage",
  components:{
    cImagePreview,
  },
  data() {
    return {
      name: '',
      path: '',
      path_pdf: '',
      type: '',
      description: '',
      preview: false,
    }
  },


  created(){

    this.loadData()

  },




  async beforeMount() {

  },



  methods:{

   async loadData(){
   //   console.log("cImage")

     var parameter = ""
     parameter = "id=" + this.id + "&table=" + this.table + "&statement=select&type=detail&"

    // console.log(Vue.prototype.$api_endpoint  + "?" + parameter)

     await this.$axios
         .get(Vue.prototype.$api_endpoint + "?" + parameter)
         .then(response => (this.responseText = response))

     if(this.responseText.length == 0){
       return
     }

     this.name = this.responseText["data"][0]["name"]
     this.description = this.responseText["data"][0]["description"]
     this.type = this.responseText["data"][0]["type"]

// console.log("Path: " +  Vue.prototype.$api_upload + this.name)
     if(this.type == "pdf"){
       this.path = Vue.prototype.$api_upload + "image_pdf.png"
       this.path_pdf =  Vue.prototype.$api_upload + this.name
     }else{
       this.path = Vue.prototype.$api_upload + this.name
     }


   },



    setPreview(val){
      console.log("setPreview")
      this.preview = val
    },

    btnZoom: function (){
      console.log("btnZoom")
      console.log("id: " + this.id)

      if(this.type == "pdf"){
        window.open(this.path_pdf)
      }
      this.preview = true

      console.log(this.preview)
      // window.scrollTo(0, 0);
    }

  },


  props: {
    id: String,
    table: String,
    value: String
  },







}
</script>

<style scoped>

</style>
