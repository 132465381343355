<template>
  <div
      style="
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 990;
  text-align: center;
  justify-content: center;
  "
  >

    <img :src="require('@/assets/images/btn_close.png')"
         style="position: absolute;
                right: 40px;
                top: 40px;
                z-index: 999;
                cursor: pointer;
                width: 35px;
                "
         v-on:click="btnClose"

         alt="">


    <img

        :src="path"
        :alt="description"
        style="

        position: absolute;
        margin: auto;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        width: auto;
        height: 100%;
      "

    >

    <v-row
        align="center"
        justify="center"
    >

    <v-btn
        outlined
        style="
        position: absolute;
        margin: 0 auto;
        background-color: red;
        bottom:50px;
        "
        v-on:click="btnDelete()"
    >Löschen</v-btn>
    </v-row>

  </div>
</template>

<script>
import Vue from "vue";

export default {
  name: "cImagePreview",
  props: {
    id: String,
    value: String,
    selector: String,
    table: String,


  },
  data() {
    return {
      name: '',
      path: '',
      description: '',
    }


  },

  created(){
    this.loadData()
  },

  async mounted() {

  },

  methods:{

    async loadData(){


      var parameter = ""

      parameter = "id=" + this.id + "&table=" + this.table + "&statement=select&type=detail&"

      console.log(Vue.prototype.$api_endpoint + "?" + parameter)


      await this.$axios
          .get(Vue.prototype.$api_endpoint + "?" + parameter)
          .then(response => (this.responseText = response))

      if(this.responseText.length == 0){
        return
      }

      this.name = this.responseText["data"][0]["name"]
      this.description = this.responseText["data"][0]["description"]
      this.type = this.responseText["data"][0]["type"]

// console.log("Path: " +  Vue.prototype.$api_upload + this.name)
      if(this.type == "pdf"){
        this.path = Vue.prototype.$api_upload + "image_pdf.png"
      }else{
        this.path = Vue.prototype.$api_upload + this.name
      }


    },



    btnClose(){
      console.log("btnClose")
      // destroy the vue listeners, etc
      // this.$destroy();

      // remove the element from the DOM
      // this.$el.parentNode.removeChild(this.$el);

      this.$emit('close', false);
    },

    async btnDelete(){
      if (confirm('Wollen Sie dieses Bild löschen?')) {


        var parameter = ""
        parameter = "id=" + this.id + "&table=" + this.table + "&statement=delete"

        await this.$axios
            .get(Vue.prototype.$api_endpoint + "?" + parameter)
            .then(response => (this.responseText = response))


        console.log(this.responseText["data"])

        this.delImage()


        this.btnClose()

      }
    },


    async delImage(){

      console.log("delImage")
      console.log(this.value)

      // Einstellungen
      var param = {
        table: "releaselists",
        statement: "update",
        type: "",
        where: "true",
        selector: "id",
        value: this.value,
        short: "false", // Übermittlung nur von id
        debug: "false",
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {
        images: "false"
      }

      var  result = await this.$func.queryAPI(param, data)
      console.log(result)
    }

  }



}
</script>

<style scoped>

</style>
