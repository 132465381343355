<template>
<div>

  <List
      v-bind:headers="headers"
      v-bind:formview="formview"
      v-bind:table="table"
      v-bind:addbutton="false"
      v-bind:default_limit="'500'"
      v-bind:default_sort="'name'"
      v-bind:default_sort_typ="'ASC'"

      v-bind:extension="''"
  ></List>

</div>
</template>

<script>

import List from '@/components/ListDefault'

export default {
  name: "ListCustomer",

  components: {
    List,
  },

  async created() {

    // Einstellungen
    var param = {
      table: "releaselists",
      statement: "select",
      type: "list",
      where: "false",
      selector: "",
      value: "",
      short: "true", // Übermittlung nur von id
      debug: "false",
      // filter: "false",
      // filterfield: "",
      // filtervalue: "",
      // operator: "true",
      // Filter noch hinzufügen
      extension: "",
    }

    // Daten -> Werden nur bei Insert oder Update gesendet
    var data = {
    }

    var result = await this.$func.queryAPI(param, data)
    console.log(result)

/*
      for(var i = 0; i < result.length; i++){
        await this.checkBeleg(result[i]["id"])
      }
*/

  },

  data () {
    return {
      search: '',
      formview: 'FormCustomer',
      table: 'customer',
    }
  },



  methods: {

    async checkBeleg(id){

      // Einstellungen
      var param = {
        table: "images",
        statement: "select",
        type: "list",
        where: "true",
        selector: "releaselists_id",
        value: id,
        short: "false", // Übermittlung nur von id
        debug: "false",
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {
      }

      var result = await this.$func.queryAPI(param, data)
      console.log(result)


      if(result.length == 0){
        // Einstellungen
        param = {
          table: "releaselists",
          statement: "update",
          type: "",
          where: "true",
          selector: "id",
          value: id,
          short: "false", // Übermittlung nur von id
          debug: "false",
          // filter: "false",
          // filterfield: "",
          // filtervalue: "",
          // operator: "true",
          // Filter noch hinzufügen
          extension: "",
        }

        // Daten -> Werden nur bei Insert oder Update gesendet
        data = {
          images: "false"
        }

        result = await this.$func.queryAPI(param, data)
        console.log(result)



        console.log("false")
        return false
      }else{

        // Einstellungen
        param = {
          table: "releaselists",
          statement: "update",
          type: "",
          where: "true",
          selector: "id",
          value: id,
          short: "false", // Übermittlung nur von id
          debug: "false",
          // filter: "false",
          // filterfield: "",
          // filtervalue: "",
          // operator: "true",
          // Filter noch hinzufügen
          extension: "",
        }

        // Daten -> Werden nur bei Insert oder Update gesendet
        data = {
          images: "true"
        }

        result = await this.$func.queryAPI(param, data)
        console.log(result)


        console.log("true")
        return true
      }


    },


  },

  computed: {
    headers () {
      return [
        { text: 'Kunde', align: 'start', sortable: true, value: 'name' },
        { text: 'Benutzername', value: 'username' },
        { text: 'Password', value: 'password' },
        { text: 'Pressejahr Anfang', value: 'pressejahr_month_start' },
        { text: '', value: 'edit_login' },
      ]
    },
  },


}
</script>

<style scoped>

</style>
