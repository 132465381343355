<template>
<div>
  <List
      v-bind:headers="headers"
      v-bind:formview="formview"
      v-bind:table="table"
      v-bind:addbutton="false"
      v-bind:default_limit="'1000'"
      v-bind:default_sort="'name'"
      v-bind:default_sort_typ="'ASC'"

      v-bind:extension="''"
  ></List>
</div>
</template>

<script>

import List from '@/components/ListDefault'


export default {
  name: "ListCities",

  components: {
    List,
  },

  data () {
    return {
      search: '',
      formview: 'FormCity',
      table: 'city',
    }
  },

  computed: {
    headers () {
      return [
        { text: 'Name', align: 'start', sortable: true, value: 'name' },
        { text: 'Bundeslandland', value: 'state' },
        { text: 'lat', value: 'lat' },
        { text: 'lng', value: 'lng' },
        { text: '', value: 'edit' },
      ]
    },
  },


}
</script>

<style scoped>

</style>
