<template>
  <v-layout
  >

    <v-card
        class="mx-auto justify-center text-center"
        width="600"
        style="padding-top: 30px;"

        elevation-10
    >
      <v-card-title primary-title class="justify-center">
        <div>
          <h3 class="headline text--accent-2">{{ this.title }}</h3>
        </div>
      </v-card-title>


      <v-form
          autocomplete="off"
      >

        <v-text-field
            v-model="name"
            sm="12"
            md="4"
            label="Name"
            required
            outlined
            type="search"
            autocomplete="off"
            style="
        display: table;
        margin: 0 auto;
        width: 350px;"

        ></v-text-field>

        <v-text-field
            v-model="username"
            sm="12"
            md="4"
            label="Benutzername"
            required
            outlined
            type="search"
            autocomplete="off"
            style="
        display: table;
        margin: 0 auto;
        width: 350px;"

        ></v-text-field>

        <v-text-field
            v-model="password"
            sm="12"
            md="4"
            label="Passwort"
            required
            outlined
            type="search"
            autocomplete="off"
            style="
      display: table;
      margin: 0 auto;
      width: 350px;"

        ></v-text-field>


        <v-select
            v-model="pressejahr_month_start"
            style="
      display: table;
      margin: 0 auto;
      width: 350px;"
            outlined
            :items="items"
            label="Anfang Pressejahr"
        ></v-select>


        <div
            style="padding: 10px;"
        ></div>

        <v-divider></v-divider>

        <div
            style="padding: 10px;"
        ></div>



        <div
            style="padding: 10px;"
        ></div>




        <!--
        </v-col>
        -->

        <v-row
            style="padding: 10px"
        >

          <v-col
              sm="10"
              md="10"
              class="text-right"
          >

            <v-btn
                color="red"
                elevation="2"
                outlined
                style="margin-right: 40px"
                @click="btnCancel"
            >Abbrechen</v-btn>


            <v-btn
                color="green"
                elevation="2"
                outlined
                @click="saveData"
            >Speichern</v-btn>


          </v-col>

        </v-row>

        <div
            style="padding: 10px;"
        ></div>


      </v-form>

      <v-spacer />


    </v-card>


    <v-snackbar
        v-model="snackbar"
        :timeout="timeout"
        :color="snackbar_color"

    >
      {{ snackbar_title }}
      <!--
            <template v-slot:action="{ attrs }">
              <v-btn
                  color="white"
                  text
                  v-bind="attrs"
                  @click="snackbar = false"
              >
                Close
              </v-btn>
            </template>
       -->

    </v-snackbar>


  </v-layout>
</template>

<script>
export default {
  name: "FormCustomer",

  data () {
    return {

      title: 'Kunde',
      mainview: 'ListCustomer',
      table: 'customer',

      snackbar: false,
      snackbar_title: 'Gespeichert!',
      snackbar_color: 'red',
      timeout: 4000,

      id: '',
      name: '',
      username: '',
      password: '',
      pressejahr_month_start: '',

      items: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],

    }
  },

  created(){

    if(this.$route.params.id != undefined){
      this.id = this.$route.params.id

      this.loadData()

    }

  },


  methods: {

    btnCancel(){

      this.$router.push({ name: this.mainview })

    },

    async loadData(){

      // Einstellungen
      var param = {
        table: this.table,
        statement: "select",
        type: "detail",
        where: "true",
        selector: "id",
        value: this.id,
        short: "true", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {
      }

      var result = await this.$func.queryAPI(param, data)
      console.log(result)

      this.name = result[0]["name"]
      this.username = result[0]["username"]
      this.password = result[0]["password"]
      this.pressejahr_month_start = result[0]["pressejahr_month_start"]

    },

    async saveData(){

      // Einstellungen
      var param = {
        table: this.table,
        statement: "update",
        type: "",
        where: "true",
        selector: "id",
        value: this.id,
        short: "true", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {

        name: this.name,
       //  customer_name: result[0]["customer_name"],
        customer_name: this.username,
        username: this.username,
        password: this.password,
        pressejahr_month_start: this.pressejahr_month_start,
      }

      var result = await this.$func.queryAPI(param, data)
      console.log(result)


    },

  }




}
</script>

<style scoped>

</style>
