<template>

  <v-container>
    <v-row dense>
      <v-col cols="12">
  <CardReleaseList
      v-for="item in List"
      :key="item.id"
      :date="item.date"
      style="margin: 15px;"
  ></CardReleaseList>
      </v-col>


    </v-row>

  </v-container>

</template>

<script>

import CardReleaseList from '../components/CardReleaseList';

export default {
  name: "ReleaseLists",

  components: {
    CardReleaseList,
  },

  data: () => ({
    date: '',
    List: [],
  }),

  created(){


    this.date = this.$moment(Date.now()).format('MM.YYYY')
    this.date = "01.2021"

    var month_counter = 0

    for (var i1 = 0; i1 < 24; i1++) {

      this.List.push({id: i1, date: this.$moment(Date.now()).subtract(month_counter,'months').endOf('month').format('MM.YYYY')})

      month_counter++

    }


    console.log("this.List")
    console.log(this.List)


  }


}
</script>

<style scoped>

</style>
