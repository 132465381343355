<template>
<div
v-if="renderComponent"
>


  <div
      style="
          position: absolute;
          right: 20px;
          top: 90px;
        "
  >

    <DialogDate
        v-bind:label="'von'"
        v-bind:initial_date="date_from"
        v-bind:clearable="false"
        v-model="date_from"
    ></DialogDate>

    <DialogDate
        v-bind:label="'bis'"
        v-bind:initial_date="date_to"
        v-bind:clearable="false"
        v-model="date_to"
    ></DialogDate>

  </div>



  <v-simple-table
      style="
      width: 50%;
      margin: 30px;
      "
      dense>
    <template v-slot:default>
      <thead>
      <tr>
        <th class="text-left">
          {{ $t("anzahlprojekte") }}
        </th>
        <th class="text-left">
          {{ $t("anzahlbelege") }}
        </th>
        <th class="text-left">
          {{ $t("auflage") }}
        </th>
        <th class="text-left">
          {{ $t("umfang") }}
        </th>
        <th class="text-left">
          {{ $t("aquivalenzwert") }}
        </th>
      </tr>
      </thead>
      <tbody>
      <tr
      >
        <td>{{ List1.length }}</td>
        <td>{{ List0[0]["COUNT(*)"] }}</td>
        <td>{{ Number(List0[0]["sum(REPLACE(REPLACE(auflage,'.',''), ',',''))"]).toLocaleString('de-DE') }}</td>
        <td>{{ Number(List0[0]["SUM(REPLACE(umfang,',','.'))"]).toFixed(0) }}</td>
        <td>{{ Number(List0[0]["SUM(aäwg)"]).toLocaleString('de-DE') }} EUR</td>

      </tr>
      </tbody>
    </template>
  </v-simple-table>




  <v-simple-table
      style="
      width: 50%;
      margin: 30px;
      "
      dense>
    <template v-slot:default>
      <thead>
      <tr>
        <th class="text-left">
          {{ $t("produkteprojekte") }}
        </th>
        <th class="text-left">
          {{ $t("anzahlbelege") }}
        </th>
      </tr>
      </thead>
      <tbody>
      <tr
          v-for="item in List1"
          :key="item.name"
      >
        <td>{{ item["projekt"] }}</td>
        <td>{{ item["COUNT(*)"] }}</td>
      </tr>
      </tbody>
    </template>
  </v-simple-table>




  <v-simple-table
      style="
      width: 50%;
      margin: 30px;
      "
      dense>
    <template v-slot:default>
      <thead>
      <tr>
        <th class="text-left">
          {{ $t("gattung") }}
        </th>
        <th class="text-left">
          {{ $t("anzahlbelege") }}
        </th>
        <th class="text-left">
          {{ $t("auflage") }}
        </th>
        <th class="text-left">
          {{ $t("umfang") }}
        </th>
      </tr>
      </thead>
      <tbody>
      <tr
          v-for="item in List3"
          :key="item.name"
      >
        <td>{{ item["gattung"] }}</td>
        <td>{{ item["COUNT(*)"] }}</td>
        <td>{{ Number(item["SUM(REPLACE(REPLACE(auflage,'.',''), ',',''))"]).toLocaleString('de-DE') }}</td>
        <td>{{ Number(item["SUM(REPLACE(umfang,',','.'))"]).toFixed(0) }}</td>
      </tr>
      </tbody>
    </template>
  </v-simple-table>



  <v-simple-table
      style="
      width: 50%;
      margin: 30px;
      "
      dense>
    <template v-slot:default>
      <thead>
      <tr>
        <th class="text-left">
          {{ $t("medium") }}
        </th>
        <th class="text-left">
          {{ $t("gattung") }}
        </th>
        <th class="text-left">
          {{ $t("ort") }}
        </th>
        <th class="text-left">
          {{ $t("anzahlbelege") }}
        </th>
      </tr>
      </thead>
      <tbody>
      <tr
          v-for="item in List2"
          :key="item.name"
      >
        <td>{{ item["medium"] }}</td>
        <td>{{ item["gattung"] }}</td>
        <td>{{ item["ort"] }}</td>
        <td>{{ item["COUNT(*)"] }}</td>
      </tr>
      </tbody>
    </template>
  </v-simple-table>








</div>
</template>

<script>

import DialogDate from "@/components/DialogDate";


export default {
  name: "TopProjects",

  components:{
    DialogDate
  },

  data: () => ({
    date: '',

    List0: Object,
    List1: Object,
    List2: Object,
    List3: Object,

    renderComponent: true,

    default_range_in_days: 180,

    date_from: '',
    date_to: ''
  }),


  async created() {


    this.List0 = [{
      "COUNT(*)": 0,
      "sum(REPLACE(REPLACE(auflage,'.',''), ',',''))": 0,
      "SUM(REPLACE(umfang,',','.'))": 0,
      "SUM(aäwg)": 0
    }]


    this.date_from = this.$moment(Date.now()).subtract(this.default_range_in_days, "days").format("DD.MM.YYYY")
    this.date_to = this.$moment(Date.now()).format("DD.MM.YYYY")

    await this.loadData()

  },

  watch:{

    date_from(){
      console.log("date_from: " + this.date_from)

      if (this.sync === true){
        return
      }

      this.loadData()
    },
    date_to(){
      console.log("date_to: " + this.date_to)

      if (this.sync === true){
        return
      }

      this.loadData()
    },




  },


  methods:{

    async loadData(){


      // Remove MyComponent from the DOM
      this.renderComponent = false;


      // Wait for the change to get flushed to the DOM
      await this.$nextTick();

      // Add the component back in
      this.renderComponent = true;

      var date_from = this.$moment(this.date_from, "DD.MM.YYYY").format("YYYY-MM-DD")
      var date_to = this.$moment(this.date_to, "DD.MM.YYYY").format("YYYY-MM-DD")


      var extension

      if (localStorage.product == "Alle" || localStorage.product == ""){

          extension = "SELECT COUNT(*), sum(REPLACE(REPLACE(auflage,'.',''), ',','')), SUM(REPLACE(umfang,',','.')), SUM(aäwg) FROM releaselists WHERE kunde='" + localStorage.customer_name + "' AND date_timestamp >= '" + date_from + "' AND date_timestamp <= '" + date_to + "' AND projekt<>'' AND lower(untertitel) NOT LIKE '%infobeleg%' AND (lower(untertitel) NOT LIKE lower('%werbung%') AND lower(projekt) NOT LIKE lower('%werbung%'))"

      }else{

        if (localStorage.product == "Oknoplast"){

          // AND (lower(projekt) NOT LIKE '%wnd%' AND lower(projekt) NOT LIKE '%aluhaus%')
          extension = "SELECT COUNT(*), sum(REPLACE(REPLACE(auflage,'.',''), ',','')), SUM(REPLACE(umfang,',','.')), SUM(aäwg) FROM releaselists WHERE kunde='" + localStorage.customer_name + "' AND (lower(projekt) NOT LIKE '%wnd%' AND lower(projekt) NOT LIKE '%aluhaus%') AND date_timestamp >= '" + date_from + "' AND date_timestamp <= '" + date_to + "' AND projekt<>'' AND lower(untertitel) NOT LIKE '%infobeleg%' AND (lower(untertitel) NOT LIKE lower('%werbung%') AND lower(projekt) NOT LIKE lower('%werbung%'))"

        }else{

          // AND (lower(projekt) LIKE '%" + localStorage.product.toString().toLowerCase()  + "%' OR lower(untertitel) LIKE '%" + localStorage.product.toString().toLowerCase() + "%')
          extension = "SELECT COUNT(*), sum(REPLACE(REPLACE(auflage,'.',''), ',','')), SUM(REPLACE(umfang,',','.')), SUM(aäwg) FROM releaselists WHERE kunde='" + localStorage.customer_name + "' AND (lower(projekt) LIKE '%" + localStorage.product.toString().toLowerCase()  + "%' OR lower(untertitel) LIKE '%" + localStorage.product.toString().toLowerCase() + "%') AND date_timestamp >= '" + date_from + "' AND date_timestamp <= '" + date_to + "' AND projekt<>'' AND lower(untertitel) NOT LIKE '%infobeleg%' AND (lower(untertitel) NOT LIKE lower('%werbung%') AND lower(projekt) NOT LIKE lower('%werbung%'))"

        }

      }


     // Original
     // extension = "SELECT COUNT(*), sum(REPLACE(REPLACE(auflage,'.',''), ',','')), SUM(REPLACE(umfang,',','.'))  FROM releaselists WHERE kunde='" + localStorage.customer_name + "' AND date_timestamp >= '" + date_from + "' AND date_timestamp <= '" + date_to + "' AND projekt<>'' AND lower(untertitel) NOT LIKE '%infobeleg%' AND (lower(untertitel) NOT LIKE lower('%werbung%') AND lower(projekt) NOT LIKE lower('%werbung%'))"


      // Einstellungen
      var param0 = {
        table: "releaselists",
        statement: "custom",
        type: "detail",
        where: "false",
        selector: "",
        value: "",
        short: "true", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: extension,
      }

      console.log("extension")
      console.log(extension)


      // Daten -> Werden nur bei Insert oder Update gesendet
      var data0 = {
      }

      var result0 = await this.$func.queryAPI(param0, data0)

      this.List0 = result0

      console.log("result0")
      console.log(result0)


      // AND date_timestamp >= '2022-10-01'



      if (localStorage.product == "Alle" || localStorage.product == ""){

        extension = "SELECT projekt, COUNT(*) FROM releaselists WHERE kunde='" + localStorage.customer_name + "' AND date_timestamp >= '" + date_from + "' AND date_timestamp <= '" + date_to + "' AND projekt<>'' AND lower(untertitel) NOT LIKE '%infobeleg%' AND lower(untertitel) NOT LIKE '%werbung%' GROUP BY projekt ORDER BY COUNT(*) DESC"

      }else{

        if (localStorage.product == "Oknoplast"){

          // AND (lower(projekt) NOT LIKE '%wnd%' AND lower(projekt) NOT LIKE '%aluhaus%')
          extension = "SELECT projekt, COUNT(*) FROM releaselists WHERE kunde='" + localStorage.customer_name + "' AND (lower(projekt) NOT LIKE '%wnd%' AND lower(projekt) NOT LIKE '%aluhaus%') AND date_timestamp >= '" + date_from + "' AND date_timestamp <= '" + date_to + "' AND projekt<>'' AND lower(untertitel) NOT LIKE '%infobeleg%' AND lower(untertitel) NOT LIKE '%werbung%' GROUP BY projekt ORDER BY COUNT(*) DESC"

        }else{

          // AND (lower(projekt) LIKE '%" + localStorage.product.toString().toLowerCase()  + "%' OR lower(untertitel) LIKE '%" + localStorage.product.toString().toLowerCase() + "%')
          extension = "SELECT projekt, COUNT(*) FROM releaselists WHERE kunde='" + localStorage.customer_name + "' AND (lower(projekt) LIKE '%" + localStorage.product.toString().toLowerCase()  + "%' OR lower(untertitel) LIKE '%" + localStorage.product.toString().toLowerCase() + "%') AND date_timestamp >= '" + date_from + "' AND date_timestamp <= '" + date_to + "' AND projekt<>'' AND lower(untertitel) NOT LIKE '%infobeleg%' AND lower(untertitel) NOT LIKE '%werbung%' GROUP BY projekt ORDER BY COUNT(*) DESC"

        }

      }

      // Original
      // extension = "SELECT projekt, COUNT(*) FROM releaselists WHERE kunde='" + localStorage.customer_name + "' AND date_timestamp >= '" + date_from + "' AND date_timestamp <= '" + date_to + "' AND projekt<>'' AND lower(untertitel) NOT LIKE '%infobeleg%' AND lower(untertitel) NOT LIKE '%werbung%' GROUP BY projekt ORDER BY COUNT(*) DESC"


      // Anzahl der Projekte

      // Einstellungen
      var param1 = {
        table: "releaselists",
        statement: "custom",
        type: "detail",
        where: "false",
        selector: "",
        value: "",
        short: "true", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: extension,
      }

      console.log("extension")
      console.log(extension)


      // Daten -> Werden nur bei Insert oder Update gesendet
      var data1 = {
      }

      var result1 = await this.$func.queryAPI(param1, data1)
      console.log(result1)

      this.List1 = result1


      // gattung, medium, ort, COUNT(*)


      if (localStorage.product == "Alle" || localStorage.product == ""){

        extension = "SELECT medium, COUNT(*) FROM releaselists WHERE kunde='" + localStorage.customer_name + "' AND date_timestamp >= '" + date_from + "' AND date_timestamp <= '" + date_to + "' AND projekt<>'' AND lower(untertitel) NOT LIKE '%infobeleg%' AND lower(untertitel) NOT LIKE '%werbung%' AND medium<>'' GROUP BY medium" // ORDER BY gattung" // ASC" //, COUNT(*) DESC"

      }else{

        if (localStorage.product == "Oknoplast"){

          // AND (lower(projekt) NOT LIKE '%wnd%' AND lower(projekt) NOT LIKE '%aluhaus%')
          extension = "SELECT medium, COUNT(*) FROM releaselists WHERE kunde='" + localStorage.customer_name + "' AND (lower(projekt) NOT LIKE '%wnd%' AND lower(projekt) NOT LIKE '%aluhaus%') AND date_timestamp >= '" + date_from + "' AND date_timestamp <= '" + date_to + "' AND projekt<>'' AND lower(untertitel) NOT LIKE '%infobeleg%' AND lower(untertitel) NOT LIKE '%werbung%' AND medium<>'' GROUP BY medium" // ORDER BY gattung ASC" //, COUNT(*) DESC"

        }else{

          // AND (lower(projekt) LIKE '%" + localStorage.product.toString().toLowerCase()  + "%' OR lower(untertitel) LIKE '%" + localStorage.product.toString().toLowerCase() + "%')
          extension = "SELECT medium, COUNT(*) FROM releaselists WHERE kunde='" + localStorage.customer_name + "' AND (lower(projekt) LIKE '%" + localStorage.product.toString().toLowerCase()  + "%' OR lower(untertitel) LIKE '%" + localStorage.product.toString().toLowerCase() + "%') AND date_timestamp >= '" + date_from + "' AND date_timestamp <= '" + date_to + "' AND projekt<>'' AND lower(untertitel) NOT LIKE '%infobeleg%' AND lower(untertitel) NOT LIKE '%werbung%' AND medium<>'' GROUP BY medium" // ORDER BY gattung ASC, COUNT(*) DESC"

        }

      }

      // Original
      // extension = "SELECT gattung, medium, ort, COUNT(*) FROM releaselists WHERE kunde='" + localStorage.customer_name + "' AND date_timestamp >= '" + date_from + "' AND date_timestamp <= '" + date_to + "' AND projekt<>'' AND lower(untertitel) NOT LIKE '%infobeleg%' AND lower(untertitel) NOT LIKE '%werbung%' AND medium <>'' GROUP BY medium ORDER BY gattung ASC, COUNT(*) DESC"


      // Anzahl der Projekte

      // Einstellungen
      var param2 = {
        table: "releaselists",
        statement: "custom",
        type: "detail",
        where: "false",
        selector: "",
        value: "",
        short: "true", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: extension,
      }

      console.log("extension")
      console.log(extension)


      // Daten -> Werden nur bei Insert oder Update gesendet
      var data2 = {
      }

      var result2 = await this.$func.queryAPI(param2, data2)
      console.log(result2)



      for (var i2 = 0; i2 < result2.length; i2++) {

        if (result2[i2]["gattung"] == "Zeitung, Regionalausgabe" || result2[i2]["gattung"] == "Zeitung" || result2[i2]["gattung"] == "Tageszeitungen" || result2[i2]["gattung"] == "Tageszeitung") {

          result2[i2]["gattung"] = "Tageszeitungen"
          i1 = i1 - 1

        }

        /*
              if (result3[i1]["gattung"] == "Anzeigen-/Offertenblätter") {
                result3[i1]["gattung"] = "Anzeigenblätter"
              }
       */

      }


// Nach Medien Gattungen sortieren

      this.List2 = result2

      console.log("result2")
      console.log(result2)




      if (localStorage.product == "Alle" || localStorage.product == ""){

        extension = "SELECT gattung, COUNT(*), SUM(REPLACE(REPLACE(auflage,'.',''), ',','')), SUM(REPLACE(umfang,',','.')) FROM releaselists WHERE kunde='" + localStorage.customer_name + "' AND date_timestamp >= '" + date_from + "' AND date_timestamp <= '" + date_to + "' AND projekt<>'' AND lower(untertitel) NOT LIKE '%infobeleg%' AND lower(untertitel) NOT LIKE '%werbung%' AND gattung <>'' GROUP BY gattung ORDER BY COUNT(*) DESC"

      }else{

        if (localStorage.product == "Oknoplast"){

          // AND (lower(projekt) NOT LIKE '%wnd%' AND lower(projekt) NOT LIKE '%aluhaus%')
          extension = "SELECT gattung, COUNT(*), SUM(REPLACE(REPLACE(auflage,'.',''), ',','')), SUM(REPLACE(umfang,',','.')) FROM releaselists WHERE kunde='" + localStorage.customer_name + "' AND (lower(projekt) NOT LIKE '%wnd%' AND lower(projekt) NOT LIKE '%aluhaus%') AND date_timestamp >= '" + date_from + "' AND date_timestamp <= '" + date_to + "' AND projekt<>'' AND lower(untertitel) NOT LIKE '%infobeleg%' AND lower(untertitel) NOT LIKE '%werbung%' AND gattung <>'' GROUP BY gattung ORDER BY COUNT(*) DESC"

        }else{

          // AND (lower(projekt) LIKE '%" + localStorage.product.toString().toLowerCase()  + "%' OR lower(untertitel) LIKE '%" + localStorage.product.toString().toLowerCase() + "%')
          extension = "SELECT gattung, COUNT(*), SUM(REPLACE(REPLACE(auflage,'.',''), ',','')), SUM(REPLACE(umfang,',','.')) FROM releaselists WHERE kunde='" + localStorage.customer_name + "' AND (lower(projekt) LIKE '%" + localStorage.product.toString().toLowerCase()  + "%' OR lower(untertitel) LIKE '%" + localStorage.product.toString().toLowerCase() + "%') AND date_timestamp >= '" + date_from + "' AND date_timestamp <= '" + date_to + "' AND projekt<>'' AND lower(untertitel) NOT LIKE '%infobeleg%' AND lower(untertitel) NOT LIKE '%werbung%' AND gattung <>'' GROUP BY gattung ORDER BY COUNT(*) DESC"

        }

      }

      // Original
      // extension = "SELECT gattung, COUNT(*), SUM(REPLACE(REPLACE(auflage,'.',''), ',','')), SUM(REPLACE(umfang,',','.')) FROM releaselists WHERE kunde='" + localStorage.customer_name + "' AND date_timestamp >= '" + date_from + "' AND date_timestamp <= '" + date_to + "' AND projekt<>'' AND lower(untertitel) NOT LIKE '%infobeleg%' AND lower(untertitel) NOT LIKE '%werbung%' AND gattung <>'' GROUP BY gattung ORDER BY COUNT(*) DESC"


      // Anzahl der Projekte

      // Einstellungen
      var param3 = {
        table: "releaselists",
        statement: "custom",
        type: "detail",
        where: "false",
        selector: "",
        value: "",
        short: "true", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: extension,
      }

      console.log("extension")
      console.log(extension)


      // Daten -> Werden nur bei Insert oder Update gesendet
      var data3 = {
      }

      var result3 = await this.$func.queryAPI(param3, data3)

      console.log("result3 output")
      console.log(result3)



      // var gattung


      var belege = 0, auflage = 0, umfang = 0



      for (var i1 = 0; i1 < result3.length; i1++) {

        if (result3[i1]["gattung"] == "Zeitung, Regionalausgabe" || result3[i1]["gattung"] == "Zeitung" || result3[i1]["gattung"] == "Tageszeitungen" || result3[i1]["gattung"] == "Tageszeitung") {

          belege = Number(belege) + Number(result3[i1]["COUNT(*)"])
          auflage = Number(auflage) + Number(result3[i1]["SUM(REPLACE(REPLACE(auflage,'.',''), ',',''))"])
          umfang = Number(umfang) + Number(result3[i1]["SUM(REPLACE(umfang,',','.'))"])

          result3.splice(i1, 1)

          i1 = i1 - 1

        }

  /*
        if (result3[i1]["gattung"] == "Anzeigen-/Offertenblätter") {
          result3[i1]["gattung"] = "Anzeigenblätter"
        }
 */

      }



      if (localStorage.product == "Alle" || localStorage.product == ""){

        extension = "SELECT COUNT(*) FROM releaselists WHERE kunde='" + localStorage.customer_name + "' AND date_timestamp >= '" + date_from + "' AND date_timestamp <= '" + date_to + "' AND projekt<>'' AND lower(untertitel) NOT LIKE '%infobeleg%' AND lower(untertitel) NOT LIKE '%werbung%' AND gattung='Online-Medien' AND newsletter='1'"

      }else{

        if (localStorage.product == "Oknoplast"){

          // AND (lower(projekt) NOT LIKE '%wnd%' AND lower(projekt) NOT LIKE '%aluhaus%')
          extension = "SELECT COUNT(*) FROM releaselists WHERE kunde='" + localStorage.customer_name + "' AND (lower(projekt) NOT LIKE '%wnd%' AND lower(projekt) NOT LIKE '%aluhaus%') AND date_timestamp >= '" + date_from + "' AND date_timestamp <= '" + date_to + "' AND projekt<>'' AND lower(untertitel) NOT LIKE '%infobeleg%' AND lower(untertitel) NOT LIKE '%werbung%' AND gattung='Online-Medien' AND newsletter='1'"

        }else{

          // AND (lower(projekt) LIKE '%" + localStorage.product.toString().toLowerCase()  + "%' OR lower(untertitel) LIKE '%" + localStorage.product.toString().toLowerCase() + "%')
          extension = "SELECT COUNT(*) FROM releaselists WHERE kunde='" + localStorage.customer_name + "' AND (lower(projekt) LIKE '%" + localStorage.product.toString().toLowerCase()  + "%' OR lower(untertitel) LIKE '%" + localStorage.product.toString().toLowerCase() + "%') AND date_timestamp >= '" + date_from + "' AND date_timestamp <= '" + date_to + "' AND projekt<>'' AND lower(untertitel) NOT LIKE '%infobeleg%' AND lower(untertitel) NOT LIKE '%werbung%' AND gattung='Online-Medien' AND newsletter='1'"

        }

      }


      // Einstellungen
      var param4 = {
        table: "releaselists",
        statement: "custom",
        type: "detail",
        where: "false",
        selector: "",
        value: "",
        short: "true", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: extension,
      }

      console.log("extension")
      console.log(extension)


      // Daten -> Werden nur bei Insert oder Update gesendet
      var data4 = {
      }

      var result4 = await this.$func.queryAPI(param4, data4)


      console.log("result4")
      console.log(result4)


      result3.unshift({gattung: "Tageszeitungen", "COUNT(*)": belege,  "SUM(REPLACE(REPLACE(auflage,'.',''), ',',''))": auflage, "SUM(REPLACE(umfang,',','.'))": umfang})

      result3.unshift({gattung: "Newsletter", "COUNT(*)": result4[0]["COUNT(*)"],  "SUM(REPLACE(REPLACE(auflage,'.',''), ',',''))": "", "SUM(REPLACE(umfang,',','.'))": result4[0]["COUNT(*)"]})


      // sort array by key
      result3.sort((a, b) => (a.gattung > b.gattung) ? 1 : -1)


      this.List3 = result3

      console.log("result3")
      console.log(result3)

    }



  }


}
</script>

<style scoped>

</style>
