import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'


import VueAxios from './plugins/axios'
import {func} from './plugins/func'
import Moment from 'vue-moment'

Vue.use(Moment);

import imagePreview from 'image-preview-vue'
import 'image-preview-vue/lib/imagepreviewvue.css'

Vue.use(imagePreview)



import CountryFlag from 'vue-country-flag'

Vue.component('country-flag', CountryFlag)



import VueVectorMap from 'vuevectormap'
// import 'vuevectormap/src/scss/vuevectormap.scss'
// require('vuevectormap/src/scss/vuevectormap.scss')

// Import your preferred map
require('jsvectormap2/dist/maps/germany')

// import jsVectorMap from 'jsvectormap'
// Vue.use(jsVectorMap)

// require('./maps/germany')

Vue.use(VueVectorMap, {
  // Set global options if any etc..
  backgroundColor: '',
  zoom: false,

  regionStyle: {
    selected: { fill: 'red' },
    selectedHover: { fill: 'purple' }
  },

})


// require('./maps/germany')



import * as VueGoogleMaps from 'vue2-google-maps'

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyCZSbq7QfOxoasTS2MEICg9l9ZeBrgdaG4',
    libraries: 'places',
  }
});

import Geocoder from "@pderas/vue2-geocoder";

Vue.use(Geocoder, {
  defaultCountryCode: 'DE', // e.g. 'CA'
  defaultLanguage:    'de', // e.g. 'en'
  defaultMode:        'address', // or 'lat-lng'
  googleMapsApiKey:   'AIzaSyCZSbq7QfOxoasTS2MEICg9l9ZeBrgdaG4'
});



import {GmapCustomMarker} from 'vue2-gmap-custom-marker';
Vue.component('GmapCustomMarker', GmapCustomMarker);


// Using Cluster requires marker-clusterer-plus to be installed.
import GmapCluster from "gmap-vue/dist/components/cluster";

import i18n from './i18n'



if(localStorage.language == undefined){
  localStorage.language = "de"
}


i18n.locale = localStorage.language




// Note: the name "cluster" below is the one to use in the template tags
Vue.component("cluster", GmapCluster);

Vue.prototype.$func = func

// Globale Variablen
// Vue.prototype.$api_endpoint = 'http://localhost/schaal/api.php'
Vue.prototype.$api_endpoint = 'https://migro.net/schaal/'
Vue.prototype.$api_upload = 'https://migro.net/schaal/upload/'
Vue.prototype.$api_hostname = window.location.host

Vue.prototype.$password = "123qweasd"

// Axios
Vue.use(VueAxios);






Vue.config.productionTip = false

new Vue({
  vuetify,
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
