<template>
  <v-app>
    <v-app-bar
        app
        clipped-left
        color="white"
        dark
    >

      <v-img
          max-height="150"
          max-width="280"
          src="./assets/logo.png"
          style="margin-right: 20px;"
      ></v-img>


      <div class="d-flex align-center">

        <v-app-bar-title
            style="
            color: black;"
            class="text-no-wrap text-left"><b>KIS</b> {{this.customer_name}} {{this.title}}</v-app-bar-title>


      </div>
<!--
      <FlagsDropdown
          style="position: absolute;
                  right: 250px;
                "
          v-on:change="optionSelected"
          :selected="country_iso"
      ></FlagsDropdown>
-->

      <div
          style="
          cursor: pointer;
          position: absolute;
          right: 250px;
          "
          @click="openLanguageDialog"

      >
      <country-flag


          :country='language' size='normal'/>
      </div>

      <v-spacer></v-spacer>


      <v-btn
          style="color: black"
          href="#"
          target="_self"
          text
          @click="btnLogin"
      >
        <span class="mr-2">Logout</span>
        <v-icon
        style="color: black"
        >mdi-logout-variant</v-icon>
      </v-btn>


    </v-app-bar>

    <Sidebar
        v-if="sidebar"
        ref="modal"
    ></Sidebar>

    <v-main
        style="
    padding: 64px 0px 0px 0px;
    "
    >


      <v-select
     v-if="product_select"

          style="
     color: black;
     width: 200px;
     top: 25px;
     margin-right: 10px;
     float: right;"
          color="black"
          outlined
          :items="select_items"
          :label="$t('marken')"
          v-model="product"

     @change="setProduct(1)"

      ></v-select>


      <v-container

          fluid
          style="padding-left: 60px;"
      >

        <router-view />

      </v-container>

    </v-main>




    <v-dialog
        v-model="select_language"
        width="500"
    >

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Language Select
        </v-card-title>

        <v-card-text>


          <div
          style="

          margin: 10px;">



            <div
                style="
                display: inline-block;
                margin-left: 20px;"
            >

              <div
                  style="
                display: inline-block;
                margin-left: 20px;"
                  @click="changeLanguage('de')"
              >
              <country-flag
                  :style="{
                  'opacity': flag_de,
                  'cursor' : 'pointer',
                  'margin-left' : '20px'
                  }"

                  country='de' size='big'/>
              </div>
              <div
                  style="
                display: inline-block;
                margin-left: 20px;"
                  @click="changeLanguage('gb')"
              >
              <country-flag
                  :style="{
                  'opacity': flag_gb,
                  'cursor' : 'pointer',
                  'margin-left' : '20px'
                   }"
                  country='gb' size='big'/>
              </div>

              <div
                  style="
                display: inline-block;
                margin-left: 20px;"
                  @click="changeLanguage('it')"
              >
              <country-flag
                  :style="{
                  'opacity': flag_it,
                  'cursor' : 'pointer',
                  'margin-left' : '20px'
                  }"
                  country='it' size='big'/>



            </div>
            </div>



          </div>




        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              outlined
              color="primary"
              text
              @click="reloadPage()"
          >
            {{ $t("accept") }}
          </v-btn>

          <v-btn
              outlined
              color="red"
              text
              @click="closeLanguageDialog()"
          >
            {{ $t("cancel") }}
          </v-btn>

        </v-card-actions>
      </v-card>
    </v-dialog>



  </v-app>
</template>

<script>
// import FlagsDropdown from 'flags-dropdown-vue'
import CountryFlag from 'vue-country-flag'
import Sidebar from './components/Sidebar';

export default {
  name: 'App',

  components: {
    Sidebar,
    // FlagsDropdown,
    CountryFlag,
  },

  data: () => ({
    title: '',
    drawer: null,
    sidebar: true,
    customer_name: localStorage.customer_name,
    items: [],
    select_items: ['Alle', 'Oknoplast', 'WnD', 'Aluhaus'],

    select_language: false,
    language: '',

    flag_de: 0.5,
    flag_gb: 0.5,
    flag_it: 0.5,

    product: '',
    product_select: false,

    country_iso : "de, en, it"


  }),

  created() {

    this.language = localStorage.language

    this.selectLanguage()


    this.title = "- Dashboard"

    if(localStorage.product == undefined){
      localStorage.product = "Alle"
    }

    this.product = localStorage.product


    if (this.$route.name != "Login" && this.$route.name != null) {

      if (localStorage.username == "" || localStorage.username == undefined) {
        this.$router.push({name: 'Login'})
      }

    } else {
      this.sidebar = false
    }



    this.items.push({name: 'Dashboard', title: '- Dashboard'})
    this.items.push({name: 'DetailReleaseList', title: '- ' + this.$t('releaselists')})
    this.items.push({name: 'FormCustomer', title: '- Kunde'})
    this.items.push({name: 'FormProject', title: '- Projekt'})
    this.items.push({name: 'FormRelease', title: '- ' })
    this.items.push({name: 'ListCustomer', title: '- Kunden'})
    this.items.push({name: 'ListProjects', title: '- Produkte'})
    this.items.push({name: 'TopProjects', title: '- ' + this.$t('topprojects')})
    this.items.push({name: 'Recherche', title: '- ' + this.$t('research')})
    this.items.push({name: 'Ads', title: '- ' + this.$t('advertisment')})
    this.items.push({name: 'Infoclip', title: '- ' + this.$t('infobelege')})
    this.items.push({name: 'ListRelease', title: '- Veröffentlichungen'})
    this.items.push({name: 'Login', title: '- Login'})
    this.items.push({name: 'Projects', title: '- Produkte'})
    this.items.push({name: 'ReleaseLists', title: '- ' + this.$t('releaselists')})
    this.items.push({name: 'Upload', title: '- Import'})


    this.setTitle()

    this.setProduct()


  },

  watch: {
    $route() {
      this.setTitle()
    },


  },

  methods: {

    reloadPage(){

      localStorage.language = this.language

      this.$router.go()
    },

    closeLanguageDialog(){

      this.language = localStorage.language
      this.select_language = false

    },



    selectLanguage(){

      switch (this.language){

        case "de":
        this.flag_de = 1
        this.flag_gb = 0.5
        this.flag_it = 0.5
        break;

        case "gb":
          this.flag_de = 0.5
          this.flag_gb = 1
          this.flag_it = 0.5
          break;


        case "it":
          this.flag_de = 0.5
          this.flag_gb = 0.5
          this.flag_it = 1
          break;

      }


    },


    changeLanguage(n){
      console.log("changeLanguage")

      this.language = n

      this.selectLanguage()
    },



    openLanguageDialog(){

      console.log("openLanguageDialog")

      this.select_language = true


    },




    optionSelected: function (data) {
      //console.log(data.id)
      //console.log(data.phonecode)
      this.country_iso = data.iso
    },


    setProduct(id){

      if(this.product == ""){
        this.product = "Alle"
        localStorage.product = this.product
      }else{
        localStorage.product = this.product
      }

      if(id == 1){
        this.$router.go()
      }


    },



    openMenu() {

      if (this.sidebar == true) {
        this.$refs.modal.open();
      }

    },

    openSideBar() {
      console.log("openSideBar")
      //this.drawer = false
    },

    btnLogin() {

      if (this.$route.name != "Login") {
        localStorage.username = ""
        localStorage.customer_name = ""
        localStorage.product = ""

        this.sidebar = false
        this.$router.push({name: 'Login'})
      }


    },

    setTitle() {

      this.customer_name = localStorage.customer_name


      if(this.customer_name.toString().toLowerCase() == "oknoplast"){
          this.customer_name = this.customer_name.toString().toLocaleUpperCase()
      }




      if (localStorage.product == undefined) {
        localStorage.product = ""
      }

      if (localStorage.username == "" || localStorage.username == undefined) {
        this.sidebar = false
        this.product_select = false
      } else {


        if(localStorage.username == "oknoplast" && (this.$route.name != "FormRelease" && this.$route.name != "Recherche" && this.$route.name != "Ads" && this.$route.name != "Infoclip")){
        this.product_select = true
        }else{
        this.product_select = false
        }



        this.sidebar = true
      }





      console.log("setSidebar: " + this.$route.name)

      let i = 0;

      while (this.items[i]) {

            if(this.$route.name == this.items[i].name){
              this.title = this.items[i].title
            }

        i++;
      }



    }

  }

}
</script>


<style>
.v-app-bar-title__content{
  margin-left: 40px;
  width: 550px !important;
}


</style>
