<template>
  <v-card

      style="
      margin: 50px;
      width: 500px;"

  >
    <v-card-title class="text-h5">
      In Bearbeitung
    </v-card-title>

    <v-card-subtitle></v-card-subtitle>





    <v-card-text
        style="font-size: large;
               text-align: left;
                "
    >


    </v-card-text>

  </v-card>
</template>

<script>
export default {
  name: "Projects"
}
</script>

<style scoped>

</style>
