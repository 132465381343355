<template>

    <div
    style="float: left"
    >
    <v-card
      v-if="visibility"
      elevation="5"
      style="
      margin: 50px;
      max-width: 500px"
      @click="openReleaseLists()"

  >
    <v-card-title class="text-h5">
      {{ $t("pressejahr") }} {{title}}
    </v-card-title>

    <v-card-subtitle>{{ $t("kumuliert") }} <b>{{year_start}}</b> {{ $t("bis") }} <b>{{year_end}}</b></v-card-subtitle>



    <v-card-text
        style="
        font-size: large;
        text-align: left;
        "
    >
      <table
      style="width: 400px;"
      >

        <tr
            style="width: 80px"
        >
          <th></th>
          <th><b>Print</b></th>
          <th><b>Online</b></th>
        </tr>

        <tr
        style="width: 250px"
        >
          <th>{{ $t("anzahlbelege") }}:</th>
          <th><b>{{belege}}</b></th>
          <th><b>{{online_belege}}</b></th>
        </tr>
        <tr>
          <th>{{ $t("auflagevisits") }}:</th>
          <th><b>{{total_auflage.toLocaleString('de-DE')}}</b></th>
          <th><b>{{online_total_auflage.toLocaleString('de-DE')}}</b></th>
        </tr>
        <tr>
          <th>{{ $t("reichweite") }}:</th>
          <th><b>{{total_reichweite.toLocaleString('de-DE')}}</b></th>
          <th><b>{{online_total_reichweite.toLocaleString('de-DE')}}</b></th>
        </tr>

        <tr>
          <th>{{ $t("aquivalenzwert") }}:</th>
          <th><b>{{total_aquivalenzwert}}</b></th>
          <th><b></b></th>
        </tr>

        <tr>
          <th>{{ $t("umfang") }}:<!-- TZ-Format:--></th>
          <th><b>{{total_print_umfang_tz}}</b></th>
          <th><b></b></th>
        </tr>


      </table>

    </v-card-text>

  </v-card>

    </div>
</template>

<script>
export default {
  name: "CardYearSummary",

  data: () => ({
    title: '',
    gattungen: [],
    produkte: [],
    belege: 0,
    total_umfang_tz: 0,
    total_print_umfang_tz: 0,
    total_umfang_zs: 0,
    total_auflage: 0,
    total_reichweite: 0,
    total_aquivalenzwert: 0,

    online_belege: 0,
    online_total_auflage: 0,
    online_total_reichweite: 0,

    year_start: '',
    year_end: '',

    year_end_yyyy: '',

    visibility: false,

  }),


  async created(){
    this.visibility = true
    this.title = this.year

    await this.calcValues()

    if (this.title != this.year_end_yyyy){
      this.title = this.year + " / " + this.year_end_yyyy
    }

    // console.log("this.year: " + this.year)
    // console.log("this.year: " + this.year_end)



  },


  methods:{

    openReleaseLists(){

      this.$router.push({ path: "/ReleaseLists" })

    },

    async calcValues(){
      // Einstellungen
      var param = {
        table: "customer",
        statement: "select",
        type: "detail",
        where: "true",
        selector: "username",
        value: localStorage.username,
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {
      }

      var result = await this.$func.queryAPI(param, data)

      console.log(result)

      if(result.length == 0){
        return
      }


      var pressejahr_month_start = result[0]["pressejahr_month_start"]
      var pressejahr_month_end = this.translateMonth(this.$moment(this.translateMonthEN(pressejahr_month_start), "MMMM").subtract(1,'months').format('MMMM'))

      // console.log("pressejahr_month_end")
      // console.log(pressejahr_month_end)


      var year_start_formated = ""
      var year_end_formated = ""

if(pressejahr_month_start != ""){


      if (this.year == this.$moment(Date.now()).format('YYYY')){

        // Aktuelles Jahr

        this.year_start = pressejahr_month_start + " " + this.$moment(Date.now()).format('YYYY')
        this.year_end = this.translateMonth(this.$moment(Date.now()).endOf('month').format('MMMM')) + " " + this.$moment(Date.now()).format('YYYY')

        year_start_formated = this.translateMonthEN(pressejahr_month_start) + " " + this.$moment(Date.now()).format('YYYY')
        year_end_formated = this.$moment(Date.now()).subtract(2,'months').endOf('month').format('MMMM') + " " + this.$moment(Date.now()).format('YYYY')

        this.year_end_yyyy = this.year

      }else{
        this.year_start = pressejahr_month_start + " " + this.year
        year_start_formated = this.translateMonthEN(pressejahr_month_start) + " " + this.year

        if(result[0]["pressejahr_month_start"] == "Januar"){
          this.year_end = "Dezember" + " " + this.year
          year_end_formated = "December" + " " + this.year

          this.year_end_yyyy = this.year
        }else{
          this.year_end = pressejahr_month_end + " " + (Number(this.year) + 1)
          year_end_formated = this.translateMonthEN(pressejahr_month_end) + " " + (Number(this.year) + 1)

          this.year_end_yyyy = (Number(this.year) + 1)

        }
      }
}else{

  if (this.year == this.$moment(Date.now()).format('YYYY')){
    this.year_start = "Januar" + " " + this.$moment(Date.now()).format('YYYY')
    this.year_end = this.translateMonth(this.$moment(Date.now()).subtract(2,'months').endOf('month').format('MMMM')) + " " + this.$moment(Date.now()).format('YYYY')

    year_start_formated = "January" + " " + this.$moment(Date.now()).format('YYYY')
    year_end_formated = this.$moment(Date.now()).subtract(2,'months').endOf('month').format('MMMM') + " " + this.$moment(Date.now()).format('YYYY')

    this.year_end_yyyy = this.year
  }else{
    this.year_start = "Januar" + " " + this.year
    this.year_end = "Dezember" + " " + this.year

    year_start_formated = "January" + " " + this.year
    year_end_formated = "December" + " " + this.year


    this.year_end_yyyy = this.year
  }

}




// console.log(year_start_formated)
// console.log(year_end_formated)
// console.log(this.$moment(year_start_formated, "MMMM YYYY").startOf('month').format('YYYY-MM-DD'))
// console.log(this.$moment(year_end_formated, "MMMM YYYY").endOf('month').format('YYYY-MM-DD'))






        var extension = ""

        if (localStorage.product == "Alle" || localStorage.product == ""){
            extension = "SELECT count(*), sum(reichweite), sum(REPLACE(REPLACE(auflage,'.',''), ',','')), sum(umfang), sum(aäwg) FROM releaselists WHERE gattung != 'Online-Medien' AND lower(untertitel) NOT LIKE '%infobeleg%' AND  lower(untertitel) NOT LIKE '%werbung%' AND customer_id='" + localStorage.customer_id + "' AND date_timestamp between '" + this.$moment(year_start_formated, "MMMM YYYY").startOf('month').format('YYYY-MM-DD') + " 00:00:00' and '" + this.$moment(year_end_formated, "MMMM YYYY").endOf('month').format('YYYY-MM-DD') + " 23:59:00'"
        }else{

            if (localStorage.product == "Oknoplast"){

                extension = "SELECT count(*), sum(reichweite), sum(REPLACE(REPLACE(auflage,'.',''), ',','')), sum(umfang), sum(aäwg) FROM releaselists WHERE gattung != 'Online-Medien' AND (lower(projekt) NOT LIKE '%wnd%' AND lower(projekt) NOT LIKE '%aluhaus%' AND lower(untertitel) NOT LIKE '%infobeleg%' AND lower(untertitel) NOT LIKE '%werbung%') AND customer_id='" + localStorage.customer_id + "' AND date_timestamp between '" + this.$moment(year_start_formated, "MMMM YYYY").startOf('month').format('YYYY-MM-DD') + " 00:00:00' and '" + this.$moment(year_end_formated, "MMMM YYYY").endOf('month').format('YYYY-MM-DD') + " 23:59:00'"

            }else{
                extension = "SELECT count(*), sum(reichweite), sum(REPLACE(REPLACE(auflage,'.',''), ',','')), sum(umfang), sum(aäwg) FROM releaselists WHERE gattung != 'Online-Medien' AND (lower(projekt) LIKE '%" + localStorage.product.toString().toLowerCase()  + "%' OR lower(untertitel) LIKE '%" + localStorage.product.toString().toLowerCase() + "%') AND (lower(untertitel) NOT LIKE '%infobeleg%' AND lower(untertitel) NOT LIKE '%werbung%') AND customer_id='" + localStorage.customer_id + "' AND date_timestamp between '" + this.$moment(year_start_formated, "MMMM YYYY").startOf('month').format('YYYY-MM-DD') + " 00:00:00' and '" + this.$moment(year_end_formated, "MMMM YYYY").endOf('month').format('YYYY-MM-DD') + " 23:59:00'"

            }

        }



      // Einstellungen
      param = {
        table: "releaselists",
        statement: "custom",
        type: "",
        where: "false",
        selector: "",
        value: "",
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: extension,
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      data = {
      }



      // console.log("param.extension")
      // console.log(param.extension)


      result = await this.$func.queryAPI(param, data)
      // console.log(result)


        if (result[0]["count(*)"] == 0){
            this.visibility = false
        }

      this.belege = Number(result[0]["count(*)"]).toLocaleString('de-DE')
      this.total_auflage = Number(result[0]["sum(REPLACE(REPLACE(auflage,'.',''), ',',''))"]).toLocaleString('de-DE')
      this.total_reichweite = Number(result[0]["sum(reichweite)"]).toLocaleString('de-DE')
      this.total_aquivalenzwert = Number(result[0]["sum(aäwg)"]).toLocaleString('de-DE')


        if (result[0]["sum(aäwg)"] != "" && result[0]["sum(aäwg)"] != "0"){
            this.total_aquivalenzwert = this.total_aquivalenzwert + " EUR"
        }



        if (localStorage.product == "Alle" || localStorage.product == ""){
            extension = "SELECT count(*), sum(reichweite), sum(REPLACE(REPLACE(auflage,'.',''), ',','')), sum(umfang), sum(aäwg) FROM releaselists WHERE gattung = 'Online-Medien' AND lower(untertitel) NOT LIKE '%infobeleg%' AND  lower(untertitel) NOT LIKE '%werbung%' AND customer_id='" + localStorage.customer_id + "' AND date_timestamp between '" + this.$moment(year_start_formated, "MMMM YYYY").startOf('month').format('YYYY-MM-DD') + " 00:00:00' and '" + this.$moment(year_end_formated, "MMMM YYYY").endOf('month').format('YYYY-MM-DD') + " 23:59:00'"
        }else{

            if (localStorage.product == "Oknoplast"){

                extension = "SELECT count(*), sum(reichweite), sum(REPLACE(REPLACE(auflage,'.',''), ',','')), sum(umfang), sum(aäwg) FROM releaselists WHERE gattung = 'Online-Medien' AND (lower(projekt) NOT LIKE '%wnd%' AND lower(projekt) NOT LIKE '%aluhaus%' AND lower(untertitel) NOT LIKE '%infobeleg%' AND lower(untertitel) NOT LIKE '%werbung%') AND customer_id='" + localStorage.customer_id + "' AND date_timestamp between '" + this.$moment(year_start_formated, "MMMM YYYY").startOf('month').format('YYYY-MM-DD') + " 00:00:00' and '" + this.$moment(year_end_formated, "MMMM YYYY").endOf('month').format('YYYY-MM-DD') + " 23:59:00'"

            }else{
                extension = "SELECT count(*), sum(reichweite), sum(REPLACE(REPLACE(auflage,'.',''), ',','')), sum(umfang), sum(aäwg) FROM releaselists WHERE gattung = 'Online-Medien' AND (lower(projekt) LIKE '%" + localStorage.product.toString().toLowerCase()  + "%' OR lower(untertitel) LIKE '%" + localStorage.product.toString().toLowerCase() + "%') AND (lower(untertitel) NOT LIKE '%infobeleg%' AND lower(untertitel) NOT LIKE '%werbung%') AND customer_id='" + localStorage.customer_id + "' AND date_timestamp between '" + this.$moment(year_start_formated, "MMMM YYYY").startOf('month').format('YYYY-MM-DD') + " 00:00:00' and '" + this.$moment(year_end_formated, "MMMM YYYY").endOf('month').format('YYYY-MM-DD') + " 23:59:00'"

            }

        }


      // Einstellungen
      param = {
        table: "releaselists",
        statement: "custom",
        type: "",
        where: "false",
        selector: "",
        value: "",
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: extension,
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      data = {
      }

      result = await this.$func.queryAPI(param, data)
      // console.log(result)



      this.online_belege = Number(result[0]["count(*)"]).toLocaleString('de-DE')
      // this.online_total_reichweite = Number(result[0]["sum(reichweite)"]).toLocaleString('de-DE')

        this.online_total_reichweite = "k.A."
        this.online_total_auflage = Number(result[0]["sum(REPLACE(REPLACE(auflage,'.',''), ',',''))"]).toLocaleString('de-DE')






      // Einstellungen
      param = {
        table: "releaselists",
        statement: "custom",
        type: "",
        where: "false",
        selector: "",
        value: "",
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "SELECT sum(replace(umfang, ',', '.')) FROM releaselists WHERE gattung!='Online-Medien' AND lower(untertitel) NOT LIKE '%infobeleg%' AND  customer_id='" + localStorage.customer_id + "' AND date_timestamp between '" + this.$moment(year_start_formated, "MMMM YYYY").startOf('month').format('YYYY-MM-DD') + " 00:00:00' and '" + this.$moment(year_end_formated, "MMMM YYYY").endOf('month').format('YYYY-MM-DD') + " 23:59:00' ",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      data = {
      }

      result = await this.$func.queryAPI(param, data)
      // console.log(result)


      this.total_print_umfang_tz = Number(result[0]["sum(replace(umfang, ',', '.'))"]).toLocaleString('de-DE')
      this.total_print_umfang_tz = String(this.total_print_umfang_tz).replace(",", ".")


        var test = this.total_auflage.toLocaleString().replace(".", "")
        test = test.replace(".", "")

        this.total_reichweite = Number(Number(test) * 2.5).toFixed(0)
        this.total_reichweite = Number(this.total_reichweite).toLocaleString('de-DE')





    },



    translateMonth(englishname){

      var output

      switch(englishname) {

        case "January":
          output = "Januar"
          break;

        case "February":
          output = "Februar"
          break;

        case "March":
          output = "März"
          break;

        case "April":
          output = "April"
          break;

        case "May":
          output = "Mai"
          break;

        case "June":
          output = "Juni"
          break;

        case "July":
          output = "Juli"
          break;

        case "August":
          output = "August"
          break;

        case "September":
          output = "September"
          break;

        case "October":
          output ="Oktober"
          break;

        case "November":
          output = "November"
          break;

        case "December":
          output = "Dezember"
          break;
      }


      return output



    },

    translateMonthEN(germanname){

      var output

      switch(germanname) {

        case "Januar":
          output = "January"
          break;

        case "Februar":
          output = "February"
          break;

        case "März":
          output = "March"
          break;

        case "April":
          output = "April"
          break;

        case "Mai":
          output = "May"
          break;

        case "Juni":
          output = "June"
          break;

        case "Juli":
          output = "July"
          break;

        case "August":
          output = "August"
          break;

        case "September":
          output = "September"
          break;

        case "Oktober":
          output ="October"
          break;

        case "November":
          output = "November"
          break;

        case "Dezember":
          output = "December"
          break;
      }


      return output



    },




  },



  props:{
    year: String,
  }


}
</script>

<style scoped>

</style>
